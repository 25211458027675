import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";
const initialState={
  //notifications:[]
}

const reducer = ( state=initialState, action ) => {
  switch ( action.type ) {  
      
    case actionTypes.NOTIFICATIONS:
     return updateObject(state, { message: action.val, success: true,status:action.status})
    default:
    return state;
  }
};
export default reducer;
