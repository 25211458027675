export const processData = (values) => {
    let returnData = [];
    if(values.error==true){
        returnData = {
            'data':values.data,
            'error':values.error,
            'notify_msg':values.message
        }
    }else{
        returnData = {
            'data':values.data,
            'error':values.error,
            'notify_msg':values.message
        }
    }
    return returnData;
}
export const processGQLData = (values) => {
    
    let returnData = [];
    if (values.error == true) {
        returnData = {
            'data': values,
            'error': "",
            'notify_msg': ""
        }
    } else {
        returnData = {
            'data': values,
            'error': "",
            'notify_msg': "",
            'is_loaded':true,
        }
    }
    return returnData;
}
