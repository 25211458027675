import React from "react";
import Select from "react-select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto"
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16
  },
  divider: {
    height: theme.spacing(2)
  }
}));

const title='';
function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps }
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.selectProps.TextFieldProps.placeholder.title}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};
const components = {
  Control,
  NoOptionsMessage,
  Option,
  Placeholder,
};

export default function IntegrationSelect(props) {
  //console.log('props===>>>>',props)
  const suggestions = props.data;
  const title = props.title;
  const classes = useStyles();
  const theme = useTheme();
  
  const [single, setSingle] = React.useState(null);


  //const [single, setSingle] = React.useState(null);
  ///console.log("single===>>.",single)
  //setSingle({value:'angola',label:'angola'});
  function handleChangeSingle(value,data) {
    setSingle(value);
    props.all_data.setState({
      [data]: value
    })
  }
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    })
  };
  let newsingle = single;
  if(newsingle==null){
    if(props.all_data.state.country!=undefined){
      newsingle = {value:props.all_data.state.country, label:props.all_data.state.country}
    }
  }
  //console.log("newsingle==>",newsingle)
  return (
    <div>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          inputId="react-select-single"
          TextFieldProps={{
           // label: "Select team",
            InputLabelProps: {
              htmlFor: "react-select-single",
              shrink: true
            },
            placeholder: {title}
          }}
          options={suggestions}
          components={components}
          value={newsingle}
          onChange={(event)=> handleChangeSingle(event,props.key_s)}
        />
      </NoSsr>
    </div>
  );
}
