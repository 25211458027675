import React, { Component } from "react";
import { Button, IconButton, Icon, withStyles, Card, DialogContent, Dialog, DialogActions, DialogTitle, Typography, MenuItem, InputLabel, Radio, FormControlLabel, RadioGroup, Badge, Drawer, Tooltip } from "@material-ui/core";
import Grid, { default as MaterialGrid } from '@material-ui/core/Grid';

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';

// import ReportSidebarMenu from "../Layout/ReportSidebarMenu";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class ActivitiesLeaderboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportTitile: "Activities Leaderboard",
            reportSubTitle: "Amount total along with the closing rate.",
            dateopen: false,
            dateformat: 'MM/DD/YYYY',
            selectedDate: { from: moment().startOf("month").format('YYYY-MM-DD'), to: moment().endOf('month').format('YYYY-MM-DD') },
            selectedDatelabel: "",
            fromdate: moment().startOf("month").format('YYYY-MM-DD'),
            todate: moment().endOf("month").format('YYYY-MM-DD'),
            activityleaderboard: [],
            memberid: 0,
        }
    }

    componentDidMount() {
        let fromDate = localStorage.getItem('dashboardFromDate');
        let toDate = localStorage.getItem('dashboardToDate');
        this.setState({
            selectedDate: { from: moment().startOf("month").format('YYYY-MM-DD'), to: moment().endOf('month').format('YYYY-MM-DD') },
            fromdate: fromDate,
            todate: toDate,
        }, () => this.updateGraphSettings())
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;
            this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
        }

        if (props.reportReducer.reportactivityleaderboard && props.reportReducer.reportactivityleaderboard.data) {
            let activityleaderboard = props.reportReducer.reportactivityleaderboard.data.data
            this.setState({ activityleaderboard });
        }
        if (props.reportReducer.UserDashboardId) {
            if (this.state.memberid !== props.reportReducer.UserDashboardId) {
                {
                    this.setState({
                        memberid: props.reportReducer.UserDashboardId
                    }, () => this.updateGraphSettings())
                }
            }
        }
    }

    componentDidUpdate(PrevProps) {
        if (PrevProps.reportReducer.UserDashboardId !== this.props.reportReducer.UserDashboardId) {
            this.setState({
                memberid: this.props.reportReducer.UserDashboardId
            }, () => this.updateGraphSettings())
        }
    }
    updateGraphSettings = () => {
        let activityparams = {
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            memberid: parseFloat(this.state.memberid)
        };
        this.props.activityLeaderboard(this.props.apolloClient.client, activityparams);
    }
    render() {
        let { dateopen, activityleaderboard, reportTitile } = this.state;
        const options = {
            chart: {
                type: 'bar',
                marginTop: 50
            },
            title: {
                text: ''
            },
            xAxis: {
                allowDecimals: false,
                title: {
                    text: 'Activity Assigned to'
                },
                categories: activityleaderboard.categories
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: 'Count of Activites'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                align: 'left',
                verticalAlign: 'top',
                floating: true,
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    borderWidth: 0,
                }
            },
            formatter: function () {
                return this.total;
            },
            series: activityleaderboard.series,

        }

        return (
            <Card elevation={3}>
                <div className="report_card">
                    <div className="report_header">
                        <Grid container justify="space-between">
                            <h3 className="text-primary">{reportTitile}</h3>
                            <Link className="viewmore text_link" to="/reports/activities-leaderboard/">View Full Report
                            </Link>
                        </Grid>
                    </div>

                    <div className="report_content">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    reportReducer: state.reportReducer,
});
const mapDispatchToProps = dispatch => {
    return {
        // activityLeaderboard
        activityLeaderboard: (client, value) => dispatch(actions.activityLeaderboard(client, value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesLeaderboard);