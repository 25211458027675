import React, { Component } from "react";
import { Button, IconButton, Icon, withStyles, Card, DialogContent, Dialog, DialogActions, DialogTitle, Typography, MenuItem, InputLabel, Radio, FormControlLabel, RadioGroup, Badge, Drawer, Tooltip } from "@material-ui/core";
import Grid, { default as MaterialGrid } from '@material-ui/core/Grid';

import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';

class SalesForecastDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportTitile: "Sales Forecast",
            reportSubTitle: "Amount forecasted to be closed based on the deal amount, win probability, and close date.",
            totalValue: "$0K",
            totalWinRate: "0%",
            selectedPipline: "1",
            selectedFilter: "1",
            value_type: "1",
            drawerOpen: false,
            dateopen: false,
            dateformat: 'MM/DD/YYYY',
            datetype: "1",

            selectedDate: { from: moment().startOf('month').format('YYYY-MM-DD'), to: moment().endOf('month').format('YYYY-MM-DD') },
            selectedDatelabel: "",
            fromdate: moment().startOf('month').subtract(2, 'months').format('YYYY-MM-DD'),
            todate: moment().endOf('month').format('YYYY-MM-DD'),
            pipelineList: [],
            salesForecastSummary: [],
            fieldDisplay: [],
            dropdownhide: [],
            systemTagsList: [],
            masterActivityTypesList: [],
            usersListArray: [],
        }
    }

    componentDidMount() {
        localStorage.removeItem('selected_report_pipeline_summary_filter_id');

        let forecastparams = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: moment().startOf('month').subtract(2, 'months').format('YYYY-MM-DD'),
            todate: moment().endOf('month').format('YYYY-MM-DD'),
            datetype: parseFloat(this.state.datetype),
        };
        this.getForecastSummary(forecastparams);

        this.props.getPipelines(this.props.apolloClient.client);
        let val = { typeId: 4 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.getTagsList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getActivityTypes(this.props.apolloClient.client);

        this.setState({ selectedDate: { from: moment().startOf('month').subtract(2, 'months').format('YYYY-MM-DD'), to: moment().endOf('month').format('YYYY-MM-DD') } })
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;
            this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
        }

        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            props.settingReducer.masterActivityTypesList.is_loaded = false
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count = dataList.filter(function (element) {
                if (element.is_disable == true) {
                    return element
                }
            });
            this.setState({
                masterActivityTypesList: dataList,
            });
        }

        if (props.settingReducer.pipelineListArray && props.settingReducer.pipelineListArray.data) {
            this.setState({ pipelineList: props.settingReducer.pipelineListArray.data })
        }

        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
            const usersListArray = props.settingReducer.usersListArray.data;
            this.setState({ usersListArray: usersListArray })
        }

        if (props.reportReducer.salesForecastSummary && props.reportReducer.salesForecastSummary.data) {
            let salesForecastSummary = props.reportReducer.salesForecastSummary.data.data

            let totalweight = 0;
            let totalwon = 0;
            if (salesForecastSummary.weight.length > 0) {
                salesForecastSummary.weight.map((weight) => {
                    totalweight += weight[1];
                })
            }
            if (salesForecastSummary.won.length > 0) {
                salesForecastSummary.won.map((won) => {
                    totalwon += won[1];
                })
            }

            this.setState({ salesForecastSummary, totalvalue: "$" + ((totalweight + totalwon) / 1000) + " k" });
        }
    }

    getForecastSummary = (forecastparams) => {
        let data = forecastparams
        data.memberid = 0;
        this.props.salesForecastSummary(this.props.apolloClient.client, data);
    }

    handlePiplineChange = (event) => {
        let forecastparams = {
            filterdata: [],
            piplineid: parseFloat(event.target.value),
            type: parseFloat(this.state.value_type),
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            datetype: parseFloat(this.state.datetype),
        };

        this.getForecastSummary(forecastparams);

        this.setState({ [event.target.name]: event.target.value })
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleOpenDrawer = () => {
        this.setState({ drawerOpen: true, })
    }

    handleCloseDrawer = () => {
        this.setState({ drawerOpen: false, })
    }

    toggle = () => {
        this.setState({ dateopen: !this.state.dateopen });
    }

    // setDateRange = (range) => {
    //     let startDate = moment.utc(range.startDate).format(this.state.dateformat);
    //     let endDate = moment.utc(range.endDate).format(this.state.dateformat);
    //     if (range.label) {
    //         this.setState({ selectedDate: range.label })
    //     } else {
    //         this.setState({ selectedDate: startDate + " To " + endDate })
    //     }
    // }

    setDateRange = (range) => {
        let startDate = moment.utc(range.startDate).format('YYYY-MM-DD');
        let endDate = moment.utc(range.endDate).format('YYYY-MM-DD');

        let forecastparams = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            datetype: parseFloat(this.state.datetype),
        };

        this.getForecastSummary(forecastparams);

        if (range.label) {
            this.setState({ selectedDatelabel: range.label })
        } else {
            this.setState({ selectedDate: { from: startDate, to: endDate } });
        }
        this.setState({ fromdate: startDate, todate: endDate })
    }

    render() {

        let { salesForecastSummary, pipelineList, reportTitile, totalValue, drawerOpen, totalWinRate, selectedPipline, value_type, datetype } = this.state;



        let totalweight = 0;
        let totalwon = 0;
        if (salesForecastSummary.weight && salesForecastSummary.weight.length > 0) {
            salesForecastSummary.weight.map((weight) => {
                totalweight += weight[1];
            })
        }
        if (salesForecastSummary.won && salesForecastSummary.won.length > 0) {
            salesForecastSummary.won.map((won) => {
                totalwon += won[1];
            })
        }
        let totalvalue1 = ((totalweight + totalwon) / 1000).toFixed(2);
        let totalWinRate1 = ((totalwon * 100) / (totalweight + totalwon)).toFixed(2);

        const options = {
            chart: {
                type: 'column'
            },
            title: "",
            subtitle: "",
            xAxis: {
                type: 'datetime',
                crosshair: true
            },
            yAxis: {
                title: { text: null },
                min: 0,
                labels: {
                    formatter: function () {
                        return '$' + this.value;
                    }
                }
            },
            tooltip: {
                headerFormat: '<b><span style="font-size:14px">{point.key}</span></b><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Forecasted (Weighted)',
                color: '#b8e0ba',
                data: salesForecastSummary.weight
            }, {
                name: 'Won',
                color: '#50ae55',
                data: salesForecastSummary.won
            }]
        }

        return (
            <Card elevation={3} className="dashboard_card mb-10">
                <div className="report_card">
                    <div className="report_header">
                        <Grid container justify="space-between">
                            <h3 className="text-primary">{reportTitile}</h3>
                            <Link className="viewmore text_link" to="/reports/sales-forecast/">View Full Report
                            </Link>
                        </Grid>
                        <p className="font-size-14 m-0 mb-10 p-0">{this.state.selectedDatelabel == "" ? this.state.selectedDate.from + " to " + this.state.selectedDate.to : this.state.selectedDatelabel}</p>
                        <Grid container direction="row" justify="flex-start" >
                            <div className="number_label">
                                Won<br /><div className="font-size-30">{totalWinRate1 + "%"}</div>
                            </div>
                            <div className="number_label">
                                Forecast<br /><div className="font-size-30">{"$" + totalvalue1 + "k"}</div>
                            </div>
                            <Select
                                className="w-200 m-10 mt-30"
                                labelId="Pipeline-select-label"
                                name="selectedPipline"
                                id="Pipeline"
                                value={selectedPipline}
                                onChange={this.handlePiplineChange}
                            >
                                {pipelineList && pipelineList.map((pipeline, i) => (
                                    <MenuItem key={i} value={pipeline.id}>{pipeline.name}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </div>
                    <div className="report_content">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    reportReducer: state.reportReducer,
});
const mapDispatchToProps = dispatch => {
    return {
        getPipelines: (client) => dispatch(actions.getPipelines(client)),
        getTagsList: (client) => dispatch(actions.getTagsList(client)),
        getUserList: (client) => dispatch(actions.getUserList(client)),
        getActivityTypes: (client) => dispatch(actions.getActivityTypes(client)),
        customFieldList: (client, value) => dispatch(actions.customFieldList(client, value)),

        salesForecastSummary: (client, value) => dispatch(actions.salesForecastSummary(client, value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesForecastDashboard);