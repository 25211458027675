import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Typography, Card } from "@material-ui/core";
import TodayDashboard from "./TodayTab/TodayDashboard"
import SalesDashboard from "./SalesTab/SalesDashboard"
import ActivityDashboard from "./ActivityTab/ActivityDashboard"
import MarketingDashboard from "./MarketingTab/MarketingDashboard"
import AdminSidebarMenu from "../Layout/AdminSidebarMenu"

import SalesForecastDashboard from "./TodayTab/SalesForecastDashboard"
import PipelineSummaryDashboard from "./TodayTab/PipelineSummaryDashboard"


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={2}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}
export default function CompanyDashboard(props) {
    const [value, setValue] = React.useState(0);
    function handleChange(event, newValue) {
        setValue(newValue);
    }
    return (
        <div className="v_scroll ml-50 mt-16 tab_card">
            <AdminSidebarMenu />
            <div className="lead_content right-16 settings_view"
                        style={{ overflowY: "auto", overflowX: "hidden" }}>

                <h1 color="primary">IDrive CRM Super Admin Dashboard</h1>
                {/* <Tabs
                    style={{
                        borderBottom: "1px solid #504f4f14"
                    }}
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    className=""
                    variant="scrollable"
                >
                    <Tab label="Sales" {...a11yProps(0)} />
                    <Tab label="Activities" {...a11yProps(1)} />
                    <Tab label="Marketing" {...a11yProps(2)} />

                </Tabs>

                <TabPanel value={value} index={0} className="h-100">
                    <SalesDashboard  />
                </TabPanel>
                <TabPanel value={value} index={1} className="h-100">
                    <ActivityDashboard  />
                </TabPanel>
                <TabPanel value={value} index={2} className="h-100">
                    <MarketingDashboard  />
                </TabPanel> */}
            </div>
        </div>
    );
}
