import React, { Component } from "react";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Link ,withRouter} from 'react-router-dom'
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index"

class ReportSidebarMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            "Pipeline": true,
            "Sales": true,
            "Activity": true,
            "reportList": [
                {
                    id: 1,
                    isaccess: true,
                    "name": "Dashboard",
                    "class": 'first_level',
                    "url": "/dashboard/"
                },
                // {
                //     id: 2,
                //     isaccess: true,
                //     "name": "Users",
                //     "class": 'first_level',
                //     "url": "/users/"
                // },
                {
                    id: 2,
                    isaccess: true,
                    "name": "Companies",
                    "class": 'first_level',
                    "url": "/companies/"
                    // "children": [
                    //     {
                    //         isaccess: true,
                    //         "name": "Pipeline Summary",
                    //         "class": 'second_level',
                    //         "url": "/reports/report-default/"
                    //     },
                    // ]
                },
                
            ]
        }
    }

    getUserPermission = () => {
        if (this.props.settingReducer.userPermissionDetails.data) {
            let udata = this.props.settingReducer.userPermissionDetails.data.data
            if (udata.reportVisibilityaccess.length > 0 &&
                udata.teampermissionstatus === true &&
                this.props.authReducer.userRole.id === 3) {
                let allreport = this.state.reportList;
                udata.reportVisibilityaccess.map((report, i) => {
                    allreport.find((list) => {
                        if (list.id === report.reporttype) {
                            list.isaccess = report.isaccess
                        }
                    })
                })
                this.setState({ reportList: allreport })
            }
        }
    }
    componentWillReceiveProps(props) {
        this.getUserPermission()
    }

    // this method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
    handleClick(item) {
        this.setState(prevState => (
            { [item]: !prevState[item] }
        ))
    }
    // if the menu item doesn't have any child, this method simply returns a clickable menu item that redirects to any location and if there is no child this method uses recursion to go until the last level of children and then returns the item by the first condition.
    handler(children, isChild) {
        const { state } = this
        return children.map((subOption) => {
            if (!subOption.children) {
                return (
                    <div key={subOption.name}>
                        <Link
                            to={subOption.url}
                            className={window.location.pathname === subOption.url ? "text_link" : ""}
                        >
                            <ListItem
                                button
                                alignItems="left"
                                key={subOption.name}>
                                <ListItemText
                                    className={subOption.class}
                                    inset={isChild ? true : false}
                                    disableTypography={isChild ? true : false}
                                    primary={subOption.name}
                                />
                            </ListItem>
                        </Link>
                    </div>
                )
            }
            if (subOption.isaccess) {
                return (
                    <div key={subOption.name}>
                        <ListItem
                            button
                            className={state[subOption.name] ? 'active' : ''}
                            onClick={() => this.handleClick(subOption.name)}>
                            <ListItemText
                                className={subOption.class}
                                primary={subOption.name} />
                            {state[subOption.name] ?
                                <ExpandLess /> :
                                <ExpandMore />
                            }
                        </ListItem>
                        <Collapse
                            in={state[subOption.name]}
                            timeout="auto"
                            unmountOnExit
                        >
                            {this.handler(subOption.children, true)}
                        </Collapse>
                    </div>
                )
            }
        })
    }
    render() {
        return (
            <React.Fragment>
                <aside className="sidenav collepse_menu">
                    <List className="side_menu">
                        {this.handler(this.state.reportList)}
                    </List>
                </aside>
            </React.Fragment>
        );
    }
}
// export default withRouter(TopNavbar);
const mapStateToProps = state => ({
    authReducer: state.authReducer,
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,

});
const mapDispatchToProps = dispatch => {
    return {
        getLoginUserPermission: (client) => dispatch(actionCreators.getLoginUserPermission(client)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportSidebarMenu)); 
