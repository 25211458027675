const initialState={
  //  counter:10,
    results:[]
}

const reducer = (state=initialState,action) => {
    
    if(action.type==="STORE_RESULT"){
      console.log("herererer")
      return {
        ...state,
        results:state.results.concat({id:new Date(),val:action.result})
      }
    }
    if(action.type==="DELETE_RESULT"){
        // const id=2;
        // const newArr = [...state.results];
        // newArr.splice(id,1)
        console.log("DELETE ARR")
        const updateArr = state.results.filter(result=>result.id!==action.resultElId)
      return {
          ...state,
        results:updateArr
      };
    }
  return state;
};
export default reducer;
