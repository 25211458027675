import * as actionTypes from './actionTypes';
import { gql } from "apollo-boost";
import * as dataSet from './dataSet';
import * as actionCreators from './index';

/**
 * TYPE : GET
 * Getting piplineSummary data
 */
export const piplineSummary = (client, value) => {

    return function (dispatch) {
        const query = gql`
                query piplineSummary($filterdata: [JSON!]!, $piplineid: Float!, $type: Float!, $fromdate: String!, $todate: String!,$memberid:Float!) 
                {
                    piplineSummary(filterdata: $filterdata, piplineid:$piplineid, type:$type, fromdate: $fromdate, todate: $todate, memberid: $memberid) {
                        status
                        message
                        data
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.piplineSummary);
            dispatch(setResponseDetails(actionTypes.GET_REPORT_PIPELINE_SUMMARY, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting ReportFilter List
 */
export const getReportFilterList = (client, value) => {

    return function (dispatch) {
        const query = gql`
                query getReportFilterList($reportno: Float!) 
                {
                    getReportFilterList(reportno:$reportno) {
                        id
                        fitertype
                        userid
                        filtername
                        filterquery
                        filteroption
                        isdefault
                        recordtypeid
                        isselected
                        reportno
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getReportFilterList);
            if (value.reportno == 1) {
                dispatch(setResponseDetails(actionTypes.GET_PIPELINE_REPORT_FILTER_LIST, return_data));
            } else if (value.reportno == 2) {
                dispatch(setResponseDetails(actionTypes.GET_FORECAST_REPORT_FILTER_LIST, return_data));
            } else if (value.reportno == 3) {
                dispatch(setResponseDetails(actionTypes.GET_PERFORMANCE_REPORT_FILTER_LIST, return_data));
            } else if (value.reportno == 4) {
                dispatch(setResponseDetails(actionTypes.GET_PERFORMANCE_SUMMARY_REPORT_FILTER_LIST, return_data));
            }



        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const saveReportFilterDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation saveReportFilterDetail($filterdata:[JSON!]!,$fitertype:String!,$filtername: String!, $reportno: Float!, $recordtypeid: Float!) {
                 saveReportFilterDetail(filterdata:$filterdata,fitertype:$fitertype,filtername:$filtername, reportno:$reportno, recordtypeid:$recordtypeid) {
                    id
                    fitertype
                    userid
                    filtername
                    filterquery
                    filteroption
                    isdefault
                    recordtypeid
                    isselected
                    reportno
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: request['savefilter']
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.saveReportFilterDetail.id) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Filter save successfully."
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    }
                }
                dispatch(actionCreators.getReportFilterList(client, request['getfilter']))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateReportFilterDetail = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateReportFilterDetail($filterdata: [JSON!]!, $fitertype:String!,$filtername:String!,$filterid:Float!) {
                 updateReportFilterDetail(filterdata:$filterdata,fitertype:$fitertype,filtername:$filtername,filterid:$filterid) {
                    id
                    fitertype
                    userid
                    filtername
                    filterquery
                    filteroption
                    isdefault
                    recordtypeid
                    isselected
                    reportno
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                filterdata: request.filterdata,
                fitertype: request.fitertype,
                filtername: request.filtername,
                filterid: request.filterid,
            }
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateReportFilterDetail.id) {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': "Filter updated successfully."
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': "Filter couldn't get updated"
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getReportFilterList(client, { reportno: request.reportno }))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const updateReportFilterDefault = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation updateReportFilterDefault($type:String!, $fiterid:Float!,$recordtypeid:Float!) {
                 updateReportFilterDefault(type:$type,fiterid:$fiterid,recordtypeid:$recordtypeid) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                type: "isdefault",
                fiterid: request.filterid,
                recordtypeid: 4
            },
        })
            .then(response => {
                let returnData = [];
                if (response.data && response.data != undefined) {
                    if (response.data.updateReportFilterDefault.status == "true") {
                        returnData = {
                            'data': {},
                            'error': false,
                            'notify_msg': response.data.updateReportFilterDefault.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                    } else {
                        returnData = {
                            'data': {},
                            'error': true,
                            'notify_msg': response.data.updateReportFilterDefault.message
                        }
                        dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                    }
                }
                dispatch(actionCreators.getReportFilterList(client, { reportno: request.reportno }))
            }).catch((error) => {
                let err_data = errorMessageGraphQl(error);
                dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
            });
    }
}

export const deleteReportFilter = (client, request) => {
    return function (dispatch) {
        const query = gql`
                query deleteReportFilter($filterid: Float!) 
                {
                    deleteReportFilter(filterid: $filterid) {
                        status
                        message
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                filterid: request.filterid
            },
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.deleteReportFilter.status == "true") {
                    returnData = {
                        'data': {},
                        'error': false,
                        'notify_msg': response.data.deleteReportFilter.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.deleteReportFilter.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
            dispatch(actionCreators.getReportFilterList(client, { reportno: request.reportno }))
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting salesForecastSummary data
 */
export const salesForecastSummary = (client, value) => {
    return function (dispatch) {
        const query = gql`
                query salesForecastSummary($filterdata: [JSON!]!, $piplineid: Float!, $datetype:Float!, $type: Float!, $fromdate: String!, $todate: String!,$memberid:Float!)  
                {
                    salesForecastSummary(filterdata: $filterdata, piplineid:$piplineid, datetype:$datetype, type:$type, fromdate: $fromdate, todate: $todate,memberid: $memberid) {
                        status
                        message
                        data
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.salesForecastSummary);
            dispatch(setResponseDetails(actionTypes.GET_REPORT_SALES_FORECAST_SUMMARY, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting salesPerformance data
 */
export const salesPerformance = (client, value) => {
    return function (dispatch) {
        const query = gql`
                query salesPerformance($filterdata: [JSON!]!, $piplineid: Float!, $datetype:Float!, $type: Float!, $fromdate: String!, $todate: String!,$memberid:Float!)  
                {
                    salesPerformance(filterdata: $filterdata, piplineid:$piplineid, datetype:$datetype, type:$type, fromdate: $fromdate, todate: $todate,memberid: $memberid) {
                        status
                        message
                        data
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.salesPerformance);
            dispatch(setResponseDetails(actionTypes.GET_REPORT_SALES_PERFORMANCE, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting salesPerformanceSummary data
 */
export const salesPerformanceSummary = (client, value) => {
    return function (dispatch) {
        const query = gql`
                query salesPerformanceSummary($filterdata: [JSON!]!, $piplineid: Float!, $datetype:Float!, $type: Float!, $fromdate: String!, $todate: String!, $timeno: Float!,$memberid:Float!) 
                {
                    salesPerformanceSummary(filterdata: $filterdata, piplineid:$piplineid, datetype:$datetype, type:$type, fromdate: $fromdate, todate: $todate, timeno: $timeno, memberid: $memberid) {
                        status
                        message
                        data
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.salesPerformanceSummary);
            dispatch(setResponseDetails(actionTypes.GET_REPORT_SALES_PERFORMANCE_SUMMARY, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting salesBySourceReport data
 */
export const salesBySourceReport = (client, value) => {
    return function (dispatch) {
        const query = gql`
                query salesBySourceReport($filterdata: [JSON!]!, $piplineid: Float!, $type: Float!, $fromdate: String!, $todate: String!,$memberid:Float!) 
                {
                    salesBySourceReport(filterdata: $filterdata, piplineid:$piplineid, type:$type, fromdate: $fromdate, todate: $todate,memberid: $memberid) {
                        status
                        message
                        data
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.salesBySourceReport);
            dispatch(setResponseDetails(actionTypes.GET_REPORT_SALES_BY_SOURCE, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting activityLeaderboard data
 */
export const activityLeaderboard = (client, value) => {
    return dispatch => {
        const query = gql`query activityLeaderboard($fromdate: String!, $todate: String!,$memberid:Float!) 
                {
                    activityLeaderboard(fromdate: $fromdate, todate: $todate,memberid: $memberid) {
                        status
                        message
                        data
                    }
                }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.activityLeaderboard);
            dispatch(setResponseDetails(actionTypes.ACTIVITY_LEADERBOARD_DATA, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting conversionFunnelReport data
 */
export const conversionFunnelReport = (client, request) => {
    return dispatch => {
        const query = gql`query conversionFunnelReport ($piplineid: Float!,$memberid:Float!) {
                    conversionFunnelReport (piplineid: $piplineid,memberid: $memberid) {
                        status
                        message
                        data
                    }
                }
        `;
        client.query({
            query: query,
            variables: request,
            fetchPolicy: 'no-cache',
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.conversionFunnelReport);
            dispatch(setResponseDetails(actionTypes.CONVERTION_FUNNEL_DATA, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting taskReportList data
 */
export const taskReportList = (client, value) => {
    return function (dispatch) {
        const query = gql`
                query taskReportList($ismytask: Boolean!, $limit: Float!, $pageno: Float!,$memberid:Float!)  {
                    taskReportList(ismytask: $ismytask, limit: $limit, pageno: $pageno,memberid: $memberid) {
                        status
                        message
                        data
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.taskReportList);
            dispatch(setResponseDetails(actionTypes.OPEN_TASK_REPORT, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

/**
 * TYPE : GET
 * Getting getActivityReport data
 */
export const getActivityReport = (client, value) => {
    return function (dispatch) {
        const query = gql`
                query getActivityReport($ismyactivity:Boolean!, $limit: Float!, $pageno: Float!,$memberid:Float!) 
                {
                    getActivityReport(ismyactivity: $ismyactivity, limit: $limit, pageno: $pageno,memberid: $memberid) {
                        status
                        message
                        data
                    }
                }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: value,
        }).then(response => {
            let return_data = dataSet.processGQLData(response.data.getActivityReport);
            dispatch(setResponseDetails(actionTypes.ACTIVITY_DASHBOARD_REPORT_LIST, return_data));
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}


const setResponseDetails = (newType, data) => {
    return {
        type: newType,
        val: data
    };
}

const notificationAll = (newType, data, status) => {
    return {
        type: newType,
        val: data,
        status: status,
    }
}

const errorMessageGraphQl = (err) => {
    let msg = 'Something Went Wrong!';
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        msg = err.graphQLErrors[0].message;
    } else if (err.networkError && err.networkError.result) {
        msg = err.networkError.result.errors[0].message;
    } else if (err.message) {
        msg = err.message;
    }

    return {
        notify_msg: msg,
        error: true
    }
}

export const clearNotification = (value) => {
    return function (dispatch) {
        dispatch(clearNotificationAll(actionTypes.NOTIFICATIONS, {}, false));
    }
}
const clearNotificationAll = (newType, data, status) => {
    return {
        type: actionTypes.NOTIFICATIONS,
        val: '',
        status: '',
    }
}

export const addLeadLogEmoji = (client, request) => {
    return dispatch => {
        const mutation = gql`
             mutation addLeadLogEmoji($logid:Float!,$emoji:String!, $emojidetail:String!,$logtype:String!) {
                addLeadLogEmoji(logid:$logid,emoji:$emoji,emojidetail:$emojidetail,logtype:$logtype) {
                    status
                    message
                 }
               }
             `;
        client.mutate({
            mutation: mutation,
            variables: {
                logid: request.logid,
                emoji: request.emoji,
                emojidetail: request.emojidetail,
                logtype: request.logtype
            }
        }).then(response => {
            let returnData = [];
            if (response.data && response.data != undefined) {
                if (response.data.addLeadLogEmoji.status == "true") {
                    let logparam = { leadid: request.leadid, emailid: request.emailid, logtypeid: request.logtypeid, activitypeid: request.activitypeid };
                    dispatch(actionCreators.getLeadActivityLogList(client, logparam))
                    dispatch(actionCreators.getLeadLogListNew(client, logparam))
                } else {
                    returnData = {
                        'data': {},
                        'error': true,
                        'notify_msg': response.data.addLeadLogEmoji.message
                    }
                    dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
                }
            }
        }).catch((error) => {
            let err_data = errorMessageGraphQl(error);
            dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
        });
    }
}

export const setUserDashboardId = (id) => {
    return function (dispatch) {
        return dispatch(setResponseDetails(actionTypes.DASHBOAD_USER_ID, id));
    }
};
