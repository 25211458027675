import React, { Component } from "react";
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  Grid,
  Card,
  withStyles,
  CircularProgress
} from "@material-ui/core";

import * as actionCreators from '../../../store/actions/index';

const styles = theme => ({
  wrapper:{
    psition:"relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'white',
  },
});
const centerDiv = {
  textAlign:'center'
}
const buttonProgress = {
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -12,
  marginLeft: -12
}



class MultipleCompany extends Component {
  
  //console.log("fsdfsdfds",this)
  state = {loading:false};
  componentWillMount(){
      let sessionData = JSON.parse(localStorage.getItem('user_data_session_admin'))
      this.setState({sessionData:sessionData})

  }
  //const 
  setDatavalue = () => {
    let sessionData = JSON.parse(localStorage.getItem('user_data_session_admin'))
    sessionData.companylist.forEach((ele,idx) => {
        //console.log(ele,"=============",idx)
       return(
        <button>helelele</button>
       )
        

    });
  }
  MultipleCompanyId = (id) => {
      let data = JSON.parse(localStorage.getItem('user_data_session_admin'))
      var req = {
        cmpId:id,
        email:data.email,
      }
      this.props.checkMultiCompany(req)  
  }

  componentWillReceiveProps(){
    this.setState({loading:false})
  }
 
  render() {
    let classes = this.props;
    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="p-20 h-100 bg-light-gray position-relative">
                   <div style={centerDiv} className={classes.centerdiv}>
                    <p>Please Select One Company</p>
                   </div>
                   
                   <div>
                   <div className={classes.root}>
                   <List component="nav" aria-label="">

                    { this.state.sessionData.companylist.map((ele,idx) => {
                        return(
                           <div>
                             <ListItem
                                button
                                onClick={()=>this.MultipleCompanyId(ele.id)}
                              >
                            {ele.displayname}
                              </ListItem>
                           </div>
                        )
                    })}
                     </List>
                </div>
                    {this.state.loading && (
                      <CircularProgress style={buttonProgress} size={50} color="inherit" />
                    )}
                   </div>
                  </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  emailData:state.authReducer.userData
});
const mapDispatchToProps = (dispatch) => ({
  resendEmail:( email ) => dispatch(actionCreators.resendEmail(email)),
  checkMultiCompany:( data ) => dispatch(actionCreators.checkMultiCompany(data)) 
})
export default withStyles(styles, {withTheme: true})(connect(mapStateToProps,mapDispatchToProps)(withRouter(MultipleCompany)));
