import * as actionTypes from '../actions/actionTypes';
import { updateObject } from "../utility";
const initialState = {
  projectList: [],
  projectLogList: [],
  projectLogSidebarList: [],
  projectListOrder: [],
  projectFieldData: [],
  projectFilterListArray: [],
  project_column_order_extra:[],
  // project_column_order_extra: [{ statusupdatedate:1001,statusduration:1002,lastcontacted:1003,interactions:1004,inactivedays:1005}],
}

const projectreducer = (state = initialState, action) => {
  switch (action.type) {
    
    case actionTypes.GET_PROJECT_LIST:
      return updateObject(state, { projectList: action.val })
    case actionTypes.GET_PROJECT_FILTER_LIST:
      return updateObject(state, { projectList: action.val })
    case actionTypes.GET_PROJECT_LOG_LIST:
      return updateObject(state, { projectLogList: action.val })
    case actionTypes.GET_PROJECT_LOG_SIDEBAR_LIST:
      return updateObject(state, { projectLogSidebarList: action.val })
    case actionTypes.GET_PROJECT_LIST_ORDER:
      return updateObject(state, { projectListOrder: action.val })
   	case actionTypes.PROJECT_FIELD_DATA:
      return updateObject(state, { projectFieldData: action.val })
    case actionTypes.GET_PROJECTFILTER_LIST:
      return updateObject(state, { projectFilterListArray: action.val })
   
      
    default:
      return state;
  }
};
export default projectreducer;
