import React, { Component } from "react";
import { IconButton, Icon, Grid, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar, Card } from "@material-ui/core";

import { connect } from "react-redux";
import { compose } from 'redux';
import LatestActivity from "./LatestActivity"
import DashboardCalenderEvent from "./DashboardCalenderEvent"
import MyOpenTasks from "./MyOpenTasks"
import SalesForecastDashboard from "./SalesForecastDashboard"
import PipelineSummaryDashboard from "./PipelineSummaryDashboard"

class TodayDashboard extends Component {
  state = {
  };
  render() {
    return (
      <React.Fragment>
        <div className="dashboard_maincntent">
          <Grid container>
            
            <Grid item xs={12}>
              <MyOpenTasks />
            </Grid>
            <Grid item xs={12}>
              <LatestActivity />
            </Grid>
            <Grid item xs={12}>
              <SalesForecastDashboard />
            </Grid>
            <Grid item xs={12}>
              <PipelineSummaryDashboard />
            </Grid>
          </Grid>
        </div>
        <div className="dashboard_right dashboard_card mb-10" >
          <DashboardCalenderEvent />
        </div>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TodayDashboard);