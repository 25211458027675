import * as actionTypes from "./actionTypes";
import { gql } from "apollo-boost";
import * as dataSet from "./dataSet";
import * as actionCreators from "./index";

export const saveTeam = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
        mutation saveTeam($teamid: Float!,$teamname: String!) {
          saveTeam(teamid:$teamid,teamname:$teamname) {           
            id
            teamname
            totalmember
          }
        }
    `;
    client.mutate({
      mutation: mutation,
      variables: {
        teamname: request.teamname,
        teamid: request.teamid,
      },
    }).then((response) => {
      let returnData = [];
      if (response.data && response.data != undefined) {
        dispatch(actionCreators.getTeam(client))
        let return_data = dataSet.processGQLData(response.data.saveTeam);
        dispatch(setResponseDetails(actionTypes.GET_TEAM_DETIALS, return_data));
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
};

export const getTeam = (client) => {
  return function (dispatch) {
    const query = gql`
      query getTeam{
        getTeam {
          id
          teamname
          totalmember         
        }
      }
    `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
    }).then((response) => {
      if (response.data && response.data != undefined) {
        let return_data = dataSet.processGQLData(response.data.getTeam);
        dispatch(setResponseDetails(actionTypes.GET_TEAM_LIST, return_data));
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
};

export const deleteTeam = (client, request) => {
  return function (dispatch) {
    const query = gql`
      query deleteTeam($teamid: Float!){
        deleteTeam(teamid: $teamid) {
          status
          message       
        }
      }
    `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: {
        teamid: request.teamid,
      }
    }).then((response) => {
      let returnData = [];
      if (response.data && response.data != undefined) {
        if (response.data.deleteTeam.status == "true") {
          returnData = {
            'data': {},
            'error': false,
            'notify_msg': response.data.deleteTeam.message
          }
          dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
        } else {
          returnData = {
            'data': {},
            'error': true,
            'notify_msg': response.data.deleteTeam.message
          }
          dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
        }
      }
      dispatch(actionCreators.getTeam(client));
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
};

export const getTeamDetail = (client, request) => {
  return function (dispatch) {
    const query = gql`
      query getTeamDetail($teamid: Float!){
        getTeamDetail(teamid: $teamid) {
          id
          teamname
          totalmember    
          memberlist{
            user{
              id
              name
              email
            }
            teammanager
          }    
        }
      }
    `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: {
        teamid: request.teamid,
      }
    }).then((response) => {
      if (response.data && response.data != undefined) {
        let return_data = dataSet.processGQLData(response.data.getTeamDetail);
        dispatch(setResponseDetails(actionTypes.GET_TEAM_DETIALS, return_data));
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
}
export const saveTeamMember = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
        mutation saveTeamMember($ismanager: Boolean!,$teamid: Float!,$userid: Float!,$memberid:Float!) {
          saveTeamMember(ismanager: $ismanager,teamid:$teamid,userid: $userid,memberid: $memberid) {           
            id
            user{
              id
              name
              email
            }
            team{
              id
              teamname
              totalmember             
            }
            teammanager
          }
        }
    `;
    client.mutate({
      mutation: mutation,
      variables: {
        ismanager: request.ismanager,
        teamid: request.teamid,
        userid: request.userid,
        memberid: request.memberid
      },
    }).then((response) => {
      let returnData = [];
      if (response.data && response.data != undefined) {
        let return_data = dataSet.processGQLData(response.data.saveTeamMember);
        let req = { teamid: request.teamid, }
        dispatch(actionCreators.getTeamMember(client, req))
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
}
export const getTeamMember = (client, request) => {
  return function (dispatch) {
    const query = gql`
      query getTeamMember($teamid: Float!){
        getTeamMember(teamid: $teamid) {
          id
          user{
            id
            name
            email
            teamlist{
              team{
                id
                teamname
                totalmember
              }
            }
          } 
          teammanager
         }
        }
    `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: {
        teamid: request.teamid,
      }
    }).then((response) => {
      if (response.data && response.data != undefined) {
        let return_data = dataSet.processGQLData(response.data.getTeamMember);
        dispatch(setResponseDetails(actionTypes.GET_TEAM_MEMBER_LIST, return_data));
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
}

export const deleteTeamMember = (client, request) => {
  return function (dispatch) {
    const query = gql`
        query deleteTeamMember($memberid: Float!){
          deleteTeamMember(memberid: $memberid) {
            status
            message       
          }
        }
      `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: {
        memberid: request.memberid,
      }
    }).then((response) => {
      let returnData = [];
      if (response.data && response.data != undefined) {
        if (response.data.deleteTeamMember.status == "true") {
          returnData = {
            'data': {},
            'error': false,
            'notify_msg': response.data.deleteTeamMember.message
          }
          dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
        } else {
          returnData = {
            'data': {},
            'error': true,
            'notify_msg': response.data.deleteTeamMember.message
          }
          dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
        }
        let req = { teamid: request.teamid }
        dispatch(actionCreators.getTeamMember(client, req));
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
};

export const getTeamVisibility = (client, request) => {
  return function (dispatch) {
    const query = gql`
        query getTeamVisibility($teamid: Float!){
          getTeamVisibility(teamid: $teamid) {
            id
            iscreate
            isaccess
            isedit
            isdelete
            team{
              id
              teamname
            }
            sourcetype
            icon
            name
            viewoption      
          }
        }
      `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: {
        teamid: request.teamid,
      }
    }).then((response) => {
      if (response.data && response.data != undefined) {
        let return_data = dataSet.processGQLData(response.data.getTeamVisibility);
        dispatch(setResponseDetails(actionTypes.GET_TEAM_ACCESS_LIST, return_data));
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  }
}
export const getTeamReportVisibility = (client, request) => {
  return function (dispatch) {
    const query = gql`
        query getTeamReportVisibility($teamid: Float!){
          getTeamReportVisibility(teamid: $teamid) {
            id
            name
            isaccess
            reporttype
            team{
              id
              teamname
            }
            viewoption
          }
        }
      `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
      variables: {
        teamid: request.teamid,
      }
    }).then((response) => {
      if (response.data && response.data != undefined) {
        let return_data = dataSet.processGQLData(response.data.getTeamReportVisibility);
        dispatch(setResponseDetails(actionTypes.GET_TEAM_REPORT_LIST, return_data));
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  }
}
export const updateTeamAccess = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
        mutation updateTeamAccess($teamid: Float!,$visibilitydata:[JSON!]!,$reportvisibility:[JSON!]!) {
          updateTeamAccess(teamid:$teamid,visibilitydata:$visibilitydata,reportvisibility:$reportvisibility) {           
            status
            message
          }
        }
    `;
    client.mutate({
      mutation: mutation,
      variables: {
        teamid: request.teamid,
        visibilitydata: request.visibilitydata,
        reportvisibility: request.reportvisibility
      },
    }).then((response) => {
      let returnData = [];
      console.log(response.data.updateTeamAccess)
      if (response.data && response.data !== undefined) {
        if (response.data.updateTeamAccess.status === "true") {
          returnData = {
            'data': {},
            'error': false,
            'notify_msg': response.data.updateTeamAccess.message
          }
          let getTeamParams = { teamid: request.teamid, };
          dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, true));
        } else {
          returnData = {
            'data': {},
            'error': true,
            'notify_msg': response.data.updateTeamAccess.message
          }
          dispatch(notificationAll(actionTypes.NOTIFICATIONS, returnData, false));
        }
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
}

export const getLoginUserPermission = (client) => {
  return function (dispatch) {
    const query = gql`
      query getLoginUserPermission{
        getLoginUserPermission {
          status
          message
          data
        }
      }
    `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
    }).then((response) => {
      if (response.data && response.data != undefined) {
        let return_data = dataSet.processGQLData(response.data.getLoginUserPermission);
        dispatch(setResponseDetails(actionTypes.GET_USER_PERMISSION, return_data));
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      // dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
};


export const getTeamSetting = (client) => {
  return function (dispatch) {
    const query = gql`
      query getTeamSetting{
        getTeamSetting {
          id
          ispermission      
        }
      }
    `;
    client.query({
      query: query,
      fetchPolicy: "no-cache",
    }).then((response) => {
      if (response.data && response.data != undefined) {
        let return_data = dataSet.processGQLData(response.data.getTeamSetting);
        dispatch(setResponseDetails(actionTypes.GET_TEAM_SETTINGS, return_data));
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
};


export const updateTeamSetting = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
    mutation updateTeamSetting($settingstatus:Boolean!) {
      updateTeamSetting(settingstatus:$settingstatus) {           
          ispermission
          id
          }
        }
    `;
    client.mutate({
      mutation: mutation,
      variables: {
        settingstatus: request.settingstatus,
      },
    }).then((response) => {
      let returnData = [];
      if (response.data && response.data != undefined) {
        let return_data = dataSet.processGQLData(response.data.updateTeamSetting);
        dispatch(actionCreators.getLoginUserPermission(client));
        dispatch(setResponseDetails(actionTypes.GET_TEAM_SETTINGS, return_data));
      }
    }).catch((error) => {
      let err_data = errorMessageGraphQl(error);
      dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
    });
  };
};

const setResponseDetails = (newType, data) => {
  return {
    type: newType,
    val: data,
  };
};

const notificationAll = (newType, data, status) => {
  return {
    type: newType,
    val: data,
    status: status,
  };
};

const errorMessageGraphQl = (err) => {
  let msg = "Something Went Wrong!";
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    msg = err.graphQLErrors[0].message;
  } else if (err.networkError && err.networkError.result) {
    msg = err.networkError.result.errors[0].message;
  } else if (err.message) {
    msg = err.message;
  }

  return {
    notify_msg: msg,
    error: true,
  };
};

export const clearNotification = (value) => {
  return function (dispatch) {
    dispatch(clearNotificationAll(actionTypes.NOTIFICATIONS, {}, false));
  };
};
const clearNotificationAll = (newType, data, status) => {
  return {
    type: actionTypes.NOTIFICATIONS,
    val: "",
    status: "",
  };
};
