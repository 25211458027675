import React, { Component } from "react";
import { FormHelperText, Input, FormControl, Popover, Divider, Button, IconButton, Icon, Paper, Card, DialogContent, Dialog, DialogActions, DialogTitle, Typography, MenuItem, InputLabel, Radio, FormControlLabel, RadioGroup, Badge, Drawer, Tooltip, Avatar } from "@material-ui/core";
import Grid, { default as MaterialGrid } from '@material-ui/core/Grid';
// import ReportSidebarMenu from "../Layout/ReportSidebarMenu";
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';

import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import CreateIcon from '@material-ui/icons/Create';
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import HttpsIcon from '@material-ui/icons/Https';

import { Link } from "react-router-dom";
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";

class SalesPerformanceSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportTitile: "Sales Performance Summary",
            reportSubTitle: "Amount total along with the closing rate.",
            totalWinRate: "0%",
            selectedPipline: "0",
            selectedFilter: "1",
            totalopp: "1",
            value_type: "1",
            drawerOpen: false,
            dateopen: false,
            dateformat: 'MM/DD/YYYY',
            // selectedDate: null,
            datetype: "1",

            selectedDate: { from: moment().startOf("month").format('YYYY-MM-DD'), to: moment().endOf('month').format('YYYY-MM-DD') },
            selectedDatelabel: "",
            timeno: 0,
            compareLabel: "",
            fromdate: moment().startOf("month").format('YYYY-MM-DD'),
            todate: moment().endOf("month").format('YYYY-MM-DD'),
            pipelineList: [],
            fieldDisplay: [],
            dropdownhide: [],
            systemTagsList: [],
            masterActivityTypesList: [],
            usersListArray: [],

            performanceSummaryArray: [{
                id: 1,
                name: "New Deals Created",
                value: 0,
                precentage: "0%",
                color: "bg-primary",
                icon: <CreateIcon />
            }, {
                id: 2,
                name: "Deal Closed Won",
                value: 0,
                precentage: "0%",
                color: "bg-green",
                icon: <AssignmentTurnedInIcon />
            }, {
                id: 3,
                name: "Deals Worked",
                value: 0,
                precentage: "0%",
                color: "bg-secondary",
                icon: <CreateIcon />
            }, {
                id: 4,
                name: "Amount Closed",
                value: 0,
                color: "bg-error",
                precentage: "0%",
                icon: <HttpsIcon />
            }],

            filterSaveDialoge: false,
            fitertype: "1",
            filterRangeArray: [],
            filterObject: {},
            filternameError: false,
            filtername: "",
            displaySelectedFilterName: "All Opportunity",
            filterdata: [],
            recordtypeid: 4,
            reportno: 4,
            filterlist: [],
            AllOpportunityFilterKey: [],
            allowStateUpdateFilter: true,
            anchorEl: null,
            isOpenedFiltersDefault: false,
            activityquery: 'any',
            memberid: 0,
        }
    }

    componentDidMount() {
        let fromDate = localStorage.getItem('dashboardFromDate');
        let toDate = localStorage.getItem('dashboardToDate');
        let timeno = localStorage.getItem('dashboardTimeNo');

        this.props.getPipelines(this.props.apolloClient.client);
        let val = { typeId: 4 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.getTagsList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getActivityTypes(this.props.apolloClient.client);
        this.setState({
            selectedDate: {
                from: moment().startOf("month").format('YYYY-MM-DD'),
                to: moment().endOf('month').format('YYYY-MM-DD')
            },
            fromdate: fromDate,
            todate: toDate, timeno
        }, () => this.updateGraphSettings())
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;
            this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
        }

        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            props.settingReducer.masterActivityTypesList.is_loaded = false
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count = dataList.filter(function (element) {
                if (element.is_disable == true) {
                    return element
                }
            });
            this.setState({
                masterActivityTypesList: dataList,
            });
        }

        if (props.settingReducer.pipelineListArray && props.settingReducer.pipelineListArray.data) {
            this.setState({ pipelineList: props.settingReducer.pipelineListArray.data })
        }

        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
            const usersListArray = props.settingReducer.usersListArray.data;
            this.setState({ usersListArray: usersListArray })
        }

        if (props.reportReducer.salesPerformanceSummary && props.reportReducer.salesPerformanceSummary.data) {
            let salesPerformanceSummary = props.reportReducer.salesPerformanceSummary.data.data;
            let performanceSummaryArray = this.state.performanceSummaryArray;

            performanceSummaryArray[0].value = salesPerformanceSummary.value.created;
            performanceSummaryArray[0].precentage = salesPerformanceSummary.percentage.created + "%";

            performanceSummaryArray[1].value = salesPerformanceSummary.value.closed;
            performanceSummaryArray[1].precentage = salesPerformanceSummary.percentage.closed + "%";

            performanceSummaryArray[2].value = salesPerformanceSummary.value.worked;
            performanceSummaryArray[2].precentage = salesPerformanceSummary.percentage.worked + "%";

            performanceSummaryArray[3].value = "$ " + salesPerformanceSummary.value.closedamnt;
            performanceSummaryArray[3].precentage = salesPerformanceSummary.percentage.closedamnt + "%";

            this.setState({ salesPerformanceSummary, performanceSummaryArray });
        }

        if (props.settingReducer.opportunitiesCustomFieldList.data) {
            const customFields = props.settingReducer.opportunitiesCustomFieldList.data;
            this.setState({ fieldsList: customFields })
        }
        if (props.reportReducer.UserDashboardId) {
            if (this.state.memberid !== props.reportReducer.UserDashboardId) {
              {
                this.setState({
                  memberid: props.reportReducer.UserDashboardId
                }, () => this.updateGraphSettings())
              }
            }
          }
    }
    componentDidUpdate(PrevProps) {
        if (PrevProps.reportReducer.UserDashboardId !== this.props.reportReducer.UserDashboardId) {
            this.setState({
                memberid: this.props.reportReducer.UserDashboardId
            }, () => this.updateGraphSettings())
        }
    }
    handlePiplineChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => this.updateGraphSettings())
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    updateGraphSettings = () => {
        let performanceparams = {
            filterdata: this.state.filterdata,
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            datetype: parseFloat(this.state.datetype),
            timeno: parseFloat(this.state.timeno),
            memberid: parseFloat(this.state.memberid)
        };        
        this.props.salesPerformanceSummary(this.props.apolloClient.client, performanceparams);
    }
    render() {
        // filter includes 
        var localStorageFilter = localStorage.getItem('selected_report_pipeline_summary_filter_id');
        let updateSaveFilterBtnDisabled = (this.state.filterdata == undefined || !this.state.filterdata.length) ? ((this.state.oldFilterOption == undefined || !this.state.oldFilterOption.length) ? true : false) : false;
        let { pipelineList, reportTitile, isOpenedFiltersDefault, selectedDatelabel, selectedDate, reportSubTitle, dateopen, totalopp, selectedFilter, totlaValue, drawerOpen, totalWinRate, selectedPipline, value_type, datetype } = this.state;
        return (
            <Card elevation={3} className="dashboard_card mb-10">
                <div className="report_card">
                    <div className="report_header">
                        <Grid container justify="space-between">
                            <h3 className="text-primary">{reportTitile}</h3>
                            <Link className="viewmore text_link" to="/reports/sales-performance-summary/">View Full Report
                            </Link>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            className="mt-40 mb-40"
                            className="crm-all-report-filters"
                        >
                            <div className="report_filter">
                                <InputLabel id="Pipeline-select-helper-label">Pipeline</InputLabel>
                                <Select
                                    className="w-200"
                                    labelId="Pipeline-select-label"
                                    name="selectedPipline"
                                    id="Pipeline"
                                    value={selectedPipline}
                                    onChange={this.handlePiplineChange}
                                >
                                    <MenuItem value={"0"}>{"All"}</MenuItem>
                                    {pipelineList && pipelineList.map((pipeline, i) => (
                                        <MenuItem key={i} value={pipeline.id}>{pipeline.name}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </Grid>
                    </div>
                    <div className="report_content">
                        <Grid container spacing={3} justify="center">
                            {this.state.performanceSummaryArray.map((fieldData, index) => (
                                <Grid item xs={3} key={index}>
                                    <Card className="p-16 font-weight-500" style={{ border: "1px solid #bdbdbd" }} >
                                        <Grid justify="space-between" container>
                                            <Grid item xs="9">
                                                <h6 className="font-weight-600 text-gray uppercase">{fieldData.name}</h6>
                                                <h3>{fieldData.value}</h3>
                                            </Grid>
                                            <Grid item xs="3">
                                                <Avatar className={fieldData.color}>
                                                    {fieldData.icon}
                                                </Avatar>
                                            </Grid>
                                        </Grid>
                                        {this.state.timeno != 0 &&
                                            <Grid>
                                                <ArrowUpwardIcon className="mr-4 text-green" style={{ marginBottom: "-5px" }} />{fieldData.precentage}{(this.state.compareLabel != "") ? "(" + this.state.compareLabel + ")" : ""}
                                            </Grid>
                                        }
                                    </Card>
                                </Grid>
                            ))}

                        </Grid>
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    reportReducer: state.reportReducer,
});
const mapDispatchToProps = dispatch => {
    return {
        getPipelines: (client) => dispatch(actions.getPipelines(client)),
        getTagsList: (client) => dispatch(actions.getTagsList(client)),
        getUserList: (client) => dispatch(actions.getUserList(client)),
        getActivityTypes: (client) => dispatch(actions.getActivityTypes(client)),
        customFieldList: (client, value) => dispatch(actions.customFieldList(client, value)),

        salesPerformanceSummary: (client, value) => dispatch(actions.salesPerformanceSummary(client, value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesPerformanceSummary);