import React, { Component } from "react";
import { Icon, Badge, IconButton, Drawer, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import moment from 'moment-timezone';
import LensIcon from '@material-ui/icons/Lens';
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

class NotificationBar extends Component {
  state = {
    panelOpen: false,
    notificationDatas: [],
    icon_length: '',
    timeZone: 'America/New_York',
    dataLoding: false,
  };

  componentWillUnmount() {
    this.getParams(this.props.data.messages);
  }

  componentDidMount() {
    this.getParams(this.props.data.messages);
  }

  componentWillReceiveProps(props) {

    this.getParams(props.data.messages);
    this.getParams(props.settingReducer.notificationList.data);

    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;
      let timezone = usersetting.timezone;
      this.setState({ timeZone: timezone })
    }
  }

  getParams = (params) => {
    let notificationData = [];
    let n_length = [];
    if (params && params !== null && params !== undefined) {
      notificationData.push(this.displayNotificationArray(params.today, "Today"));
      notificationData.push(this.displayNotificationArray(params.yesterday, "Yesterday"));
      notificationData.push(this.displayNotificationArray(params.lastweek, "Last Week"));
      notificationData.push(this.displayNotificationArray(params.thisweek, "This Week"));
      notificationData.push(this.displayNotificationArray(params.thismonth, "This Month"));
      notificationData.push(this.displayNotificationArray(params.lastmonth, "Last Month"));
      notificationData.push(this.displayNotificationArray(params.earlier, "Earlier"))
      notificationData.map((notification) => (
        notification.notificationData.map((log) => {
          n_length.push(log)
        })
      ));
      let ilength = n_length.reduce(function (n, n_length) {
        return n + (n_length.isRead === true);
      }, 0);
      this.setState({
        notificationDatas: notificationData, icon_length: ilength
      })
    }
  }

  handleDrawerToggle = () => {
    this.setState({ panelOpen: !this.state.panelOpen });
  };

  handleNotificationReadDot = (id, key) => {
    let l_data = [];
    let test = [... this.state.notificationDatas];
    let found = test.find(function (element) { return element.partkey === key; });
    let found_ID = found.notificationData.find(function (element) { return element.id === id; });
    found_ID.isRead = false;
    test.map((log) => { log.notificationData.map((data) => { l_data.push(data) }) })
    const i_conlength = l_data.reduce(function (n, l_data) { return n + (l_data.isRead === true); }, 0);
    this.props.notificationReadData(this.props.apolloClient.client, id);
    this.setState({ notificationDatas: test, icon_length: i_conlength });
  }

  handleNotificationRedirect = (id, key) => {
    let l_data = [];
    let test = [... this.state.notificationDatas]
    let found = test.find(function (element) { return element.partkey === key; });
    let found_ID = found.notificationData.find(function (element) { return element.id === id; });
    found_ID.isRead = false;
    test.map((log) => { log.notificationData.map((data) => { l_data.push(data); }) })
    const i_conlength = l_data.reduce(function (n, l_data) { return n + (l_data.isRead === true); }, 0);
    this.props.notificationReadData(this.props.apolloClient.client, id);
    this.setState({ notificationDatas: test, icon_length: i_conlength });
    this.handleDrawerToggle();
  }

  handleMakeAsAllRead = (e) => {
    let l_data = [];
    let test = [... this.state.notificationDatas];
    test.map((log) => { log.notificationData.map((data) => { l_data.push(data); data.isRead = false; }) })
    this.props.notificationReadData(this.props.apolloClient.client, e);
    this.setState({ notificationDatas: test, icon_length: 0 });
  };

  findWithAttr = (array, attr, value) => {
    if (array !== null && attr !== null && value !== null) {
      for (var i = 0; i < array.length; i++) {
        if (i !== null) {
          if (array[i].__field__ !== null) {
            if (array[i].__field__[attr] === value) {
              return i;
            }
          }
        }
      }
    }
    return -1;
  }

  displayNotificationArray = (data, partkey) => {
    let notificationData = [];
    if (data && data.length > 0) {
      data.map((ndata) => {
        if (ndata) {
          let date_time = moment.unix(ndata.createddate).tz(this.state.timeZone).format('hh:mm');
          let n_id = ndata.id;
          let source = '';
          let pathid = '';
          let new_template = '';
          let path = '';
          let color_class = '';
          let child_path = '';
          let primary_association = '';
          let Export_icon = '';
          let Export_path = '';

          if (ndata.text === "Opportunities Export" || ndata.text === "Opportunities Import") {
            Export_icon = "business";
          }
          else if (ndata.text === "Task Export" || ndata.text === "Task Import") {
            Export_icon = "list";
          }
          else if (ndata.text === "Project Export" || ndata.text === "Project Import") {
            Export_icon = "business_center";
          }
          else if (ndata.text === "Company Export" || ndata.text === "Company Import") {
            Export_icon = "supervised_user_circle";
          }
          else if (ndata.text === "Contact Export" || ndata.text === "Contact Import") {
            Export_icon = "group";
          }
          else if (ndata.text === "Lead Export" || ndata.text === "Lead Import") {
            Export_icon = "supervised_user_circle";
          } else {
            Export_icon = "supervised_user_circle";
          }

          if (ndata.sourcetype === 1) {
            new_template = "Lead";
            path = "/lead/leads-list/";
            if (ndata.sourcedetail) {
              pathid = ndata.sourcedetail.id;
            }
            if (pathid) {
              child_path = `/lead/lead/${pathid}`
            }
            color_class = "supervised_user_circle";
            if (ndata.sourcedetail) {
              let leadSource = ndata.sourcedetail.__leaddetail__;
              if (leadSource) {
                let leadkey = this.findWithAttr(leadSource, 'fieldkey', 'name');
                if (leadkey !== -1) {
                  if (leadSource[leadkey] && leadSource[leadkey].fieldvalue) {
                    source = leadSource[leadkey].fieldvalue;
                  }
                }
              }
            }
          }
          else if (ndata.sourcetype === 2) {
            new_template = "Contact";
            path = "/contact/contact-list/";
            if (ndata.sourcedetail) {
              pathid = ndata.sourcedetail.id;
            }
            if (pathid) {
              child_path = `/contact/contact/${pathid}`
            }
            color_class = "group";
            if (ndata.sourcedetail && ndata.sourcedetail !== null) {
              let ContactSource = ndata.sourcedetail.__contactdetail__;
              if (ContactSource && ContactSource !== null && ContactSource !== undefined) {
                let Contactkey = this.findWithAttr(ContactSource, 'fieldkey', 'name');
                if (Contactkey !== -1) {
                  if (ContactSource[Contactkey] && ContactSource[Contactkey].fieldvalue) {
                    source = ContactSource[Contactkey].fieldvalue;
                  }
                }
              }
            }
          }
          else if (ndata.sourcetype === 3) {
            new_template = "Company";
            path = "/company/company-list/";
            if (ndata.sourcedetail) {
              pathid = ndata.sourcedetail.id;
            }
            if (pathid) {
              child_path = `/company/company/${pathid}`
            }
            color_class = "supervised_user_circle";
            if (ndata.sourcedetail) {
              let CompanySource = ndata.sourcedetail.__companydetail__;
              if (CompanySource) {
                let Companykey = this.findWithAttr(CompanySource, 'fieldkey', 'name');
                if (Companykey !== -1) {
                  if (CompanySource[Companykey] && CompanySource[Companykey].fieldvalue) {
                    source = CompanySource[Companykey].fieldvalue;
                  }
                }
              }
            }
          }
          else if (ndata.sourcetype === 4) {
            new_template = "Opportunities";
            path = "/opportunities/opportunities-list/";
            if (ndata.sourcedetail) {
              pathid = ndata.sourcedetail.id;
            }
            if (pathid) {
              child_path = `/opportunities/opportunities/${pathid}`
            }
            color_class = "business";
            if (ndata.sourcedetail) {
              let opportunitiesSource = ndata.sourcedetail.__opportunitiesdetail__;
              if (opportunitiesSource) {
                let opportunitieskey = this.findWithAttr(opportunitiesSource, 'fieldkey', 'name');
                if (opportunitieskey !== -1) {
                  if (opportunitiesSource[opportunitieskey] && opportunitiesSource[opportunitieskey].fieldvalue) {
                    source = opportunitiesSource[opportunitieskey].fieldvalue;
                  }
                }
              }
            }
          }
          else if (ndata.sourcetype === 5) {
            new_template = "Project";
            path = "/project/projects-list/";
            if (ndata.sourcedetail) {
              pathid = ndata.sourcedetail.id;
            }
            if (pathid) {
              child_path = `/project/projects/${pathid}`
            }
            color_class = "business_center";
            if (ndata.sourcedetail) {
              let projectSource = ndata.sourcedetail.__projectdetail__;
              if (projectSource) {
                let projectkey = this.findWithAttr(projectSource, 'fieldkey', 'name');
                if (projectkey !== -1) {
                  if (projectSource[projectkey] && projectSource[projectkey].fieldvalue) {
                    source = projectSource[projectkey].fieldvalue;
                  }
                }
              }
            }
          }
          else if (ndata.sourcetype === 6) {
            new_template = "Task";
            path = "/task/task-list";
            if (ndata.sourcedetail) {
              pathid = ndata.sourcedetail.id;
            }
            if (pathid) {
              child_path = `/task/task-list`
            }
            color_class = "list";
            if (ndata.sourcedetail) {
              let taskSource = ndata.sourcedetail.__taskdetail__;
              if (taskSource) {
                let taskkey = this.findWithAttr(taskSource, 'fieldkey', 'name');
                if (taskkey !== -1) {
                  if (taskSource[taskkey] && taskSource[taskkey].fieldvalue) {
                    source = taskSource[taskkey].fieldvalue;
                  }
                }
              }
            }
            if (ndata.primary_association) {
              let primary_associationSource = ndata.primary_association;
              if (primary_associationSource) {
                primary_association = primary_associationSource.fieldvalue;
              }
            }
          }
          if (ndata.notificationtype === 1) {
            let user = (ndata.__user__) ? ndata.__user__.name : "";
            let noti_template = ndata.template.replace("{{user}}", user).replace("{{source_type}}", new_template).replace("{{source}}", source).replace("{{old_value}}", ndata.oldvalue).replace("{{new_value}}", ndata.newvalue);
            notificationData.push({
              template: noti_template,
              date: date_time,
              id: n_id,
              isRead: ndata.unread,
              icon: color_class,
              link: child_path
            });
          } else if (ndata.notificationtype === 2) {
            let user = (ndata.__user__) ? ndata.__user__.name : "";
            let noti_temaplate = ndata.template.replace("{{source_type}}", new_template).replace("{{source}}", source).replace("{{user}}", user).replace("{{target}}", ndata.__target__.name);
            notificationData.push({
              template: noti_temaplate,
              date: date_time,
              id: n_id,
              isRead: ndata.unread,
              icon: color_class,
              link: path
            });
          } else if (ndata.notificationtype === 3) {
            let user = (ndata.__user__) ? ndata.__user__.name : "";
            let noti_temaplate = ndata.template.replace("{{user}}", user).replace("{{source_type}}", new_template).replace("{{source}}", source).replace("{{property_name}}", ndata.propertyname).replace("{{old_value}}", ndata.oldvalue).replace("{{new_value}}", ndata.newvalue);
            notificationData.push({
              template: noti_temaplate,
              date: date_time,
              id: n_id,
              isRead: ndata.unread,
              icon: color_class,
              link: child_path
            })
          } else if (ndata.notificationtype === 4) {
            let noti_temaplate = ndata.template.replace("{{user}}", "You");
            notificationData.push({
              template: noti_temaplate + '.!',
              date: date_time,
              id: n_id,
              isRead: ndata.unread,
              icon: "person",
              link: ""
            })
          } else if (ndata.notificationtype === 9 || ndata.notificationtype === 11 || ndata.notificationtype === 10) {
            let user = (ndata.__user__) ? ndata.__user__.name : "";
            let noti_temaplate = ndata.template.replace("{{user}}", user).replace("{{text}}", ndata.text).replace("{{source_type}}", new_template).replace("{{source}}", source);
            notificationData.push({
              template: noti_temaplate,
              date: date_time,
              id: n_id,
              isRead: ndata.unread,
              icon: color_class,
              link: child_path
            })
          } else if (ndata.notificationtype === 5) {
            let user = (ndata.__user__) ? ndata.__user__.name : "";
            let noti_temaplate = ndata.template.replace("{{user}}", user).replace("{{property_name}}", ndata.propertyname).replace("{{source_type}}", new_template).replace("{{source}}", source);
            notificationData.push({
              template: noti_temaplate,
              date: date_time,
              id: n_id,
              isRead: ndata.unread,
              icon: color_class,
              link: child_path
            })
          } else if (ndata.notificationtype === 6) {
            let noti_temaplate = ndata.template.replace("{{primary_association}}", primary_association).replace("{{user}}", ndata.__user__.name).replace("{{target}}", ndata.__target__.name).replace("{{source_type}}", new_template).replace("{{source}}", source);
            notificationData.push({
              template: noti_temaplate,
              date: date_time,
              id: n_id,
              isRead: ndata.unread,
              icon: color_class,
              link: path
            })
          } else if (ndata.notificationtype === 7 || ndata.notificationtype === 8) {
            let template = ndata.template.replace("{{primary_association}}", primary_association).replace("{{user}}", ndata.__user__.name).replace("{{target}}", ndata.__target__.name).replace("{{source_type}}", new_template).replace("{{source}}", source).replace("{{old_value}}", ndata.oldvalue).replace("{{new_value}}", ndata.newvalue);
            notificationData.push({
              template: template,
              date: date_time,
              id: n_id,
              isRead: ndata.unread,
              icon: color_class,
              link: child_path
            })
          } else if (ndata.notificationtype === 12 || ndata.notificationtype === 13) {
            let template = ndata.template.replace("{{primary_association}}", primary_association).replace("{{new_value}}", ndata.newvalue).replace("{{source_type}}", new_template).replace("{{source}}", source);
            notificationData.push({
              template: template,
              date: date_time,
              id: n_id,
              isRead: ndata.unread,
              icon: color_class,
              link: child_path
            })
          } else if (ndata.notificationtype === 14) {
            let template = ndata.template;
            notificationData.push({
              template: template,
              date: date_time,
              id: n_id,
              isRead: ndata.unread,
              icon: Export_icon,
              link: '',
            })
          } else if (ndata.notificationtype === 15) {
            let template = ndata.template.replace("{{text}}", ndata.text);
            notificationData.push({
              template: template,
              date: date_time,
              id: n_id,
              isRead: ndata.unread,
              icon: Export_icon,
              link: '',
            })
          }
        }
      })
    }
    return { notificationData, partkey };
  }
  render() {
    let { panelOpen, notificationDatas, icon_length } = this.state
    return (
      <React.Fragment>
        <IconButton className="btn-white" onClick={this.handleDrawerToggle}>
          <Badge color="secondary" badgeContent={icon_length}>
            <Icon>notifications</Icon>
          </Badge>
        </IconButton>
        <Drawer
          className="NotificationsDrawer_drawer"
          width={"100px"}
          variant="temporary"
          anchor={"right"}
          open={panelOpen}
          onClose={this.handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}>
          <div className="notification" >
            <div className="notification__topbar d-flex align-items-center">
              <div style={{ padding: ' 0 0 0 10px' }}>
                <IconButton onClick={this.handleDrawerToggle}>
                  <ArrowForwardIcon />
                </IconButton>
              </div>
              <h5 className="ml-8 my-0 font-weight-500">Notifications</h5>
              <div style={{ margin: "0 0 0 73px" }}>
                <IconButton onClick={() => this.handleMakeAsAllRead(0)}  >
                  <Tooltip title="MarK All Read" placement="bottom">
                    <DoneAllIcon />
                  </Tooltip>
                </IconButton>
              </div>
              <div style={{ margin: "5px 0 0 5px" }} >
                <Tooltip title="Notification Settings" placement="bottom">
                  <Link onClick={this.handleDrawerToggle} to="/settings/notifications/">
                    <Icon>settings</Icon>
                  </Link>
                </Tooltip>
              </div>
            </div>
            <div className="notification__card">
              {notificationDatas.map((notification, i) => (
                <div className="card-blank" key={i}>
                  {(notification.notificationData.length) > 0 ? <div className="card_data_font">{notification.partkey}</div> : ""}
                  {notification.notificationData.map((log, index) => (
                    <div className="card card-1" key={index}>
                      {(log.template != undefined && log.date != undefined && log.id != undefined) ?
                        <div className="card__topbar" >
                          <div className="d-flex">
                            <Link onClick={() => this.handleNotificationRedirect(log.id, notification.partkey)} to={log.link}>
                              <div className="d-flex">
                                <div className="card__topbar__button" >
                                  <IconButton aria-label="Pin" size="small" className="hover_show">
                                    <Icon>{log.icon}</Icon>
                                  </IconButton>
                                </div>
                                <span className="card_font" style={{ padding: '10px 0 0 10px', width: '178px' }} >
                                  {log.template}
                                </span>
                              </div>
                            </Link>
                            <small style={{ padding: '10px 0px 0px 35px' }}>
                              {log.date}
                              <div>
                                {(log.isRead === true) ?
                                  <Tooltip title="Mark Read" placement="left">
                                    <IconButton aria-label="Pin" size="small" onClick={() => this.handleNotificationReadDot(log.id, notification.partkey)} className="button_size hover_show">
                                      <LensIcon style={{ color: '#2B41B2', width: "9px" }} />
                                    </IconButton>
                                  </Tooltip>
                                  : ""}
                              </div>
                            </small>
                          </div>
                        </div>
                        : ""}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </Drawer>
      </React.Fragment >
    );
  }
}
const mapStateToProps = state => {
  return {
    apolloClient: state.apolloClient,
    settingReducer: state.settingReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNotificationsList: (client) => dispatch(actionCreators.getNotificationsList(client)),
    notificationReadData: (client, request) => dispatch(actionCreators.notificationReadData(client, request)),
  }
}
export default (connect(mapStateToProps, mapDispatchToProps)(NotificationBar));