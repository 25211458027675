import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";


const mystyle = {
  height: '200px'
};

class UnAuthorized extends Component {
  state = {};
  render() {
    return (
      <div className="flex flex-center flex-middle w-100 h-100vh">
        <div className="flex flex-column flex-center flex-middle" style={{ maxWidth: "380px" }}>
          <img className="mb-32" style={mystyle} src="/assets/images/401.png" alt="" />
          <span className="font-size-16 font-weight-500 m-4">Looks like you don't have access to the this Section.</span>
          <span className="m-4">If you need access to this section contact your  admin.</span>
          <Button
            className="capitalize"
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push('/')}
          >
            Back to Dashboard
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(UnAuthorized);
