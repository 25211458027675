import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from "redux-thunk";
import authReducer from '../store/reducers/auth';
import resultReducer from '../store/reducers/result';
import notification from '../store/reducers/notification';
import settingReducer from '../store/reducers/setting';
import leadReducer from '../store/reducers/lead';
import taskReducer from '../store/reducers/task';
import companyReducer from '../store/reducers/company';
import contactReducer from '../store/reducers/contact';
import apolloClient from '../store/reducers/apolloClient';
import opportunityReducer from '../store/reducers/opportunity';
import projectReducer from '../store/reducers/project';
import reportReducer from '../store/reducers/report';


const rootReducer = combineReducers({
  authReducer: authReducer,
  resultReducer: resultReducer,
  leadReducer: leadReducer,
  companyReducer: companyReducer,
  contactReducer: contactReducer,
  notification: notification,
  settingReducer: settingReducer,
  taskReducer: taskReducer, 
  apolloClient: apolloClient,
  opportunityReducer: opportunityReducer,
  projectReducer: projectReducer,
  reportReducer: reportReducer,
});

const logger = store => {
  return next => {
    return action => {
      const result = next(action);
      return result;
    };
  };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));

export default store;