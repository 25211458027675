import React, { Component } from "react";
import { Grid, Button, withStyles, CircularProgress, Backdrop, Container } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import * as actionCreators from '../../../store/actions/index';
import GoogleLogin from 'react-google-login';
import { Link } from "react-router-dom";
const styles = theme => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});
class SignUp extends Component {
  state = {
    name: "",
    email: "",
    msg: "",
    open: false,
    loading: false
  };

  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  componentWillReceiveProps(props) {
    this.setState({ loading: false })
    if (props.userData.userData.error == false) {
      this.props.history.push("/email/confirm-email")
    }
  }
  handleFormSubmit = event => {
    this.setState({ loading: true })
    this.props.onRegisterUser({ ...this.state })
  };

  handleGoogleAuthSucess = (load_response) => {
    this.props.loginWithGoogle(load_response);
  };
  handleGoogleAuthFailure() {

  }

  handleBlur = (event,ref) => {
      this[ref].validate(event.target.value);
  }

  render() {
    let { name, email } = this.state;
    let { classes } = this.props;
    return (
      <Container maxWidth="md" className="h-100vh">
        <Grid container justify="center" alignItems="center" className="h-100">
          <div className="center_bx">
            <Grid container>
              <Grid item sm={6} xs={12}>
                <div className="pl-50 pr-50 py-80">
                  <h3 className="text-center font-weight-bold">Sign Up</h3>
                  <p className="text-center mt-0">Start selling more today.</p>
                  <ValidatorForm  noValidate instantValidate={false} onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      ref={ref => this.nameRef = ref}
                      onBlur={(event)=>this.handleBlur(event,'nameRef')}
                      className="mb-16 w-100"
                      label="FullName"
                      onChange={this.handleChange}
                      type="text"
                      name="name"
                      required={true}
                      value={name}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      autoComplete="off"
                    />
                    <TextValidator
                      ref={ref => this.emailRef = ref}
                      onBlur={(event)=>this.handleBlur(event,'emailRef')}
                      className="mb-24 w-100"
                      label="Email"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      required={true}
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "This field is required",
                        "email is not valid"
                      ]}
                      autoComplete="off"
                    />
                    <Button variant="contained" fullWidth color="primary" type="submit" className="mb-16">Sign Up</Button>
                    <GoogleLogin
                      // clientId={"161942936141-fsq8al02npjinbf8vm1rr1lop0takre0.apps.googleusercontent.com"}
                      clientId={"965427486424-jsdger0lt68ncnd49jki1ojpjkaemndc.apps.googleusercontent.com"}
                      buttonText={"Continue With Google"}
                      onSuccess={this.handleGoogleAuthSucess}
                      onFailure={this.handleGoogleAuthFailure}
                      responseType="code"
                      className="google_btn"
                      //isSignedIn={true}
                      uxMode='redirect'
                      redirectUri=''
                      scope="https://www.googleapis.com/auth/drive https://mail.google.com https://www.googleapis.com/auth/calendar profile email openid"
                    />
                    <p className="text-center"><Link to="/" className="link link_primary">Sign In</Link></p>
                    {this.state.loading && (
                      <Backdrop
                        className={classes.backdrop}
                        open={this.state.loading}
                      >
                        <CircularProgress size={60} color="inherit" />
                      </Backdrop>
                    )}
                  </ValidatorForm>
                </div>
              </Grid>
              <Grid item sm={6} xs={12} className="layers_bg align-items-end d-flex">
                <div>
                  <h2>Hello there!</h2>
                  <p>Meet the crm that allows you to nurture leads and close deals from your inbox. <br />Create an Account here and start selling more today.</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Container>
    );
  }
}
const mapStateToProps = state => ({
  userData: state.authReducer,
  login: state.login
});
const mapDispatchToProps = dispatch => {
  return {
    onRegisterUser: (response_data) => dispatch(actionCreators.registerUser(response_data)),
    loginWithGoogle: (response) => dispatch(actionCreators.loginWithGoogle(response))
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignUp)
);
