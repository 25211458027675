import * as actionTypes from "./actionTypes";
import { gql } from "apollo-boost";
import axios from 'axios';
import * as dataSet from "./dataSet";
import * as actionCreators from "./index";
import Server from "../../Common/Server"

const config = {
  headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
  },
};

export const GetLoginToken = (value) => {
  return function (dispatch) {
      return axios.post(Server.API + 'SuperadminToken/LoginToken', value, config)
      .then(({ data }) => {
          let resp = { userid: value.userid, type: "profile" }
          let return_data = dataSet.processData(data);
          dispatch(setResponseDetails(actionTypes.SET_LOGIN_TOKEN, return_data));
          dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data, true));
      }).catch(function (error) {
          dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
      })
  }
}


// export const GetLoginToken = (data) => {
//   return function (dispatch) {
//       return axios.post(Server.API + "SuperadminToken/LoginToken", data, config)
//           .then(({ data }) => {
//               let return_data = dataSet.processData(data);
//               console.log(return_data)

//               // if (data.error == false) {
//               //     console.log("fdfsdfsdfdf===", data.data)
//               //     if (data.data !== null) {
//               //         setUserSession(data.data);
//               //         dispatch(setResponseDetails(actionTypes.LOGIN_WITH_EMAIL_AND_PASSWORD, return_data));
//               //     }
//               //     dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
//               // } else {
//               //     dispatch(notificationAll(actionTypes.NOTIFICATIONS, return_data));
//               // }
//           })
//           .catch(function (error) {
//               dispatch(notificationAll(actionTypes.NOTIFICATIONS, errorMessageAll()));
//           });
//   };
// }

export const getAllUser = (client, request) => {
  return dispatch => {
      const query = gql`query getAllUser($limit: Float!,$pageno:Float!) {
                  getAllUser(limit: $limit,pageno:$pageno){
                    status
                    message
                    data
                  }
              }
      `;
      client.query({
          query: query,
          fetchPolicy: 'no-cache',
          variables: request,
      }).then(response => {
          let return_data = dataSet.processGQLData(response.data.getAllUser);
          dispatch(setResponseDetails(actionTypes.ALL_USERS_LIST_LIST, return_data));
      }).catch((error) => {
          //let err_data = errorMessageGraphQl(error);
          //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  }
}

export const getCompanyList = (client, request) => {
  return dispatch => {
      const query = gql`query getCompanyList ($limit: Float!,$pageno:Float!, $fromdate:String, $todate:String) {
                  getCompanyList (limit: $limit,pageno:$pageno, fromdate:$fromdate, todate:$todate){
                    status
                    message
                    data
                  }
              }
      `;
      client.query({
          query: query,
          fetchPolicy: 'no-cache',
          variables: request,
      }).then(response => {
          let return_data = dataSet.processGQLData(response.data.getCompanyList);
          dispatch(setResponseDetails(actionTypes.ALL_COMPANIES_LIST_LIST, return_data));
      }).catch((error) => {
          //let err_data = errorMessageGraphQl(error);
          //dispatch(notificationAll(actionTypes.NOTIFICATIONS, err_data));
      });
  }
}

const errorMessageAll = () => {
  return {
      notify_msg: "Something went wrong, please try again later.",
      error: true
  }
}

const setResponseDetails = (newType, data) => {
  return {
    type: newType,
    val: data,
  };
};

const notificationAll = (newType, data, status) => {
  return {
    type: newType,
    val: data,
    status: status,
  };
};

const errorMessageGraphQl = (err) => {
  let msg = "Something Went Wrong!";
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    msg = err.graphQLErrors[0].message;
  } else if (err.networkError && err.networkError.result) {
    msg = err.networkError.result.errors[0].message;
  } else if (err.message) {
    msg = err.message;
  }

  return {
    notify_msg: msg,
    error: true,
  };
};

export const clearNotification = (value) => {
  return function (dispatch) {
    dispatch(clearNotificationAll(actionTypes.NOTIFICATIONS, {}, false));
  };
};
const clearNotificationAll = (newType, data, status) => {
  return {
    type: actionTypes.NOTIFICATIONS,
    val: "",
    status: "",
  };
};
