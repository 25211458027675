import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Icon, IconButton, MenuItem, Avatar } from "@material-ui/core";
import { navigations } from "../navigations";
import TopSearchBox from "./TopSearchBox";
import DropdownMenu from "./DropdownMenu";
import NotificationAppbar from "./NotificationAppBar";
import UserProfileDialog from "../Profile/profile"
import { connect } from "react-redux";
import * as actionCreators from '../../store/actions/index';
const styles = theme => ({
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  });

class TopNavbar extends Component {
    state = {
        shouldOpenProfileDialog: false,
        imagePreview: '',
        isloading:false,
    }
    componentDidMount() {
        let userData = JSON.parse(localStorage.getItem('user_data_session_admin'))
        let val = { userid: userData.id }
        this.props.getUserSetting(this.props.apolloClient.client, val);
        this.props.customFieldTypeList(this.props.apolloClient.client);
        this.props.getUserIntegration(this.props.apolloClient.client);
        this.props.getPipelines(this.props.apolloClient.client);
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userPermissionDetails.data) {
            this.setState({isloading:false})

            let udata = props.settingReducer.userPermissionDetails.data.data
            if (udata.visibilityaccess.length > 0 &&
                udata.teampermissionstatus === true &&
                this.props.authReducer.userRole.id === 3) {
                udata.visibilityaccess.map((page, i) => {
                    navigations.find((element) => {
                        if (element.sourcetype === page.sourcetype && element.sourcetype !== 4) {
                            if (page.isaccess === true) {
                                element.isaccess = true
                            } else {
                                element.isaccess = false
                            }
                        }
                    })
                })
                navigations[4].isaccess = false;

                if (props.settingReducer.pipelineListArray && props.settingReducer.pipelineListArray.data) {
                    let pipelines = props.settingReducer.pipelineListArray.data;

                    pipelines.map((pipeline)=>{
                        let pil1 = udata.visibilityaccess.find(function (e) { return e.piplineid === parseInt(pipeline.id) });
                        
                        if (pil1.isaccess === true) {
                            navigations[4].isaccess = true
                        }
                    })
                }
                // let pil1 = udata.visibilityaccess.find(function (e) { return e.piplineid === 1 });
                // let pil2 = udata.visibilityaccess.find(function (e) { return e.piplineid === 2 });
                
                console.log(navigations[4].isaccess,'navigations[4].isaccess');
            }
        }
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let user_datas = props.settingReducer.userSettingArray.data;
            let profile_image = user_datas.profileimage ? user_datas.profileimage : null;
            this.setState({ imagePreview: profile_image })
        }
    }
    openSetting = () => {
        this.props.history.push("/settings/my-preferences/")
    }
    redirectPage = (url) => {
        window.location.href = url;
    }

    logoutUser = () => {
        localStorage.removeItem('user_data_session_admin')
        // localStorage.removeItem('selected_contact_filter_id')
        // localStorage.removeItem('selected_lead_filter_id')
        // localStorage.removeItem('selected_company_filter_id')
        localStorage.clear();
        window.location.href = '/'
    }
    handleLeadDialogClose = () => {
        this.setState({ shouldOpenProfileDialog: false });
    };
    render() {
        let userData = JSON.parse(localStorage.getItem('user_data_session_admin'))
        
        let { shouldOpenProfileDialog } = this.state;
        function renderLevels(levels) {
            return levels.map((item, key) => {
                if (item.isaccess) {
                    return (
                        <li key={key}>
                            <NavLink
                                className={window.location.pathname === item.path ? "selectedmainMenu" : ""}
                                to={item.path}>
                                {item.icon &&
                                    (
                                        <Icon className="item-icon text-middle">
                                            {item.icon}
                                        </Icon>
                                    )}
                                {item.name}
                            </NavLink>
                        </li>
                    )
                }
            });
        }
        return (
            this.state.isloading ?
            <body style={{zIndex:'9999'}}>
                <noscript>You need to enable JavaScript to run this app.</noscript>
                    <div id="root">
                        <div class="page-loader">
                        <img
                            src="/assets/images/logo-circle.svg"
                            alt=""
                        />
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        </div>
                    </div>
                    
            </body>
            :
                <div className="navbar pt-10" >
                    <div className="container">
                        <div className="d-flex align-items-center h-100">
                            <div className="d-flex align-items-center brand">
                            <img src="/assets/images/headerlogo.png" alt="company-logo" style={{ width: '80px', height: '40px', marginBottom: '10px' }} />
                            </div>
                            <div className="d-flex flex-auto justify-content-between">
                                <ul className="menu">{renderLevels(navigations)}</ul>
                                <div className="position-relative align-items-center d-flex" >
                                    {/* <TopSearchBox /> */}
                                    <NotificationAppbar />
                                    <DropdownMenu
                                        menuButton={
                                            <Avatar className="mx-8 text-middle circular-image-small cursor-pointer" alt="Profile Picture" src={this.state.imagePreview} />
                                        }
                                    >
                                        <MenuItem
                                            className="d-flex align-items-center "
                                            style={{ minWidth: 185 }}
                                            onClick={() => this.redirectPage("/")}
                                        >
                                            <Icon> home </Icon>
                                            <span className="pl-16"> Home </span>
                                        </MenuItem>
                                        {/* <MenuItem
                                            className="d-flex align-items-center "
                                            style={{ minWidth: 185 }}
                                            onClick={() => this.setState({ shouldOpenProfileDialog: true })}
                                        >
                                            <Icon> person </Icon>
                                            <span className="pl-16"> Profile </span>
                                        </MenuItem> */}
                                        <MenuItem
                                            className="d-flex align-items-center "
                                            style={{ minWidth: 185 }}
                                            onClick={() => this.logoutUser()}
                                        >
                                            <Icon> power_settings_new </Icon>
                                            <span className="pl-16"> Logout </span>
                                        </MenuItem>
                                    </DropdownMenu>
                                </div>
                            </div>
                        </div>
                    </div>
                    { shouldOpenProfileDialog && (
                        <UserProfileDialog
                            handleClose={this.handleLeadDialogClose}
                            open={shouldOpenProfileDialog}
                        />
                    )}
                </div >
        );
    }
}

// export default withRouter(TopNavbar);
const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    authReducer: state.authReducer
});
const mapDispatchToProps = dispatch => {
    return {
        getUserSetting: (client, data) => dispatch(actionCreators.getUserSetting(client, data)),
        customFieldTypeList: (client) => dispatch(actionCreators.customFieldTypeList(client)),
        getUserIntegration: (client) => dispatch(actionCreators.getUserIntegration(client)),
        getPipelines: (client) => dispatch(actionCreators.getPipelines(client)),

        // getUserProfileInfoFirst: (data) => dispatch(actionCreators.getUserProfileInfoFirst(data)),
        // updateCompanyInfo: (data) => dispatch(actionCreators.updateCompanyInfo(data)),
        // getBussinessType: () => dispatch(actionCreators.getBussinessType()),
        // GetCompanyStrength: () => dispatch(actionCreators.GetCompanyStrength()),
        // companyListById: (data) => dispatch(actionCreators.companyListById(data)),
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(TopNavbar)));
