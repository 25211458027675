import React, { Component } from "react";
import { Select, InputLabel, IconButton, Icon, Grid, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar, Card } from "@material-ui/core";

import { connect } from "react-redux";
import { compose } from 'redux';

import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from 'moment';//moment
import 'moment-timezone';//timezone

import SalesForecast from "./SalesForecast"
import PipelineSummary from "./PipelineSummary"
import SalesPerformance from "./SalesPerformance"
import SalesPerformanceSummary from "./SalesPerformanceSummary"
import CommanDateFilter from "../CommanDateFilter"

class TodayDashboard extends Component {

  state = {
    dateopen: false,
    dateformat: 'MM/DD/YYYY',
    selectedDate: { from: moment().startOf("month").format('YYYY-MM-DD'), to: moment().endOf('month').format('YYYY-MM-DD') },
    selectedDatelabel: "",
    selectedDateid: "1",
    fromdate: moment().startOf("month").format('YYYY-MM-DD'),
    todate: moment().endOf("month").format('YYYY-MM-DD'),
  };


  render() {
    return (
      <Card elevation={3} className="dashboard_card mb-10">
        <Grid container>
          <Grid item xs={12}>
            <CommanDateFilter />
          </Grid>
          <Grid item xs={12}>
            <SalesPerformanceSummary />
          </Grid>
          <Grid item xs={12}>
            <PipelineSummary />
          </Grid>
          <Grid item xs={12}>
            <SalesForecast />
          </Grid>
          <Grid item xs={12}>
            <SalesPerformance />
          </Grid>
        </Grid>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TodayDashboard);