import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Snackbar, Fade, CircularProgress, Icon, Card, IconButton, Dialog, DialogActions, InputLabel, DialogTitle, Fab, Avatar, Checkbox, Typography, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";
import ConfirmationDialog from './ConfirmationDialog';
import CreateNewUser from './CreateNewUser'
import AdminSidebarMenu from "../Layout/AdminSidebarMenu";

import Server from "../../Common/Server"

import {
    Grid,

    Table,
    TableHeaderRow,
    Toolbar,
    VirtualTable,
    TableSelection,
    SearchPanel,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    SearchState,
    IntegratedSorting,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
    PagingState,
    IntegratedPaging,
    TableColumnResizing,
    CustomPaging,
} from '@devexpress/dx-react-grid';
import * as actionCreators from '../../store/actions/index';
import FontAwesome from "react-fontawesome";
import { default as MaterialGrid } from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";


class userManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            UserListArr: [],
            openEditDialog: false,
            selectoptions: [],
            confirmationDialog: false,
            delete_id: '',
            shouldOpenCreateDialog: false,
            selection: [],
            isDataLoading: true,
            comp_name: "",
            isEdit: false,
            onlySystemAdminCreate: false,
            limit: 10,
            currentPage: 0,
            totalCount: 30,
        }
    }
    componentDidMount() {
        let userdata = JSON.parse(localStorage.getItem('user_data_session_admin'));
        var req = {
            companyid: userdata.companyId
        }
        // this.props.getCompanyDetail(this.props.apolloClient.client, req)
        this.props.getUserList(this.props.apolloClient.client)
        let userparams = { limit: parseInt(this.state.limit), pageno: parseInt(this.state.currentPage) };
        this.props.getAllUser(this.props.apolloClient.client, userparams)
    }

    componentWillReceiveProps(props) {
        if (props.authReducer.alluserList && props.authReducer.alluserList.data && props.authReducer.alluserList.data.data) {
            let UserListArr = props.authReducer.alluserList.data.data.user;

            let totalRecord = props.authReducer.alluserList.data.data.totalrecord;
            this.setState({ UserListArr, isDataLoading: false, totalCount: totalRecord })
        } else {
            setTimeout(() => {
                this.setState({ isDataLoading: false })
            }, 1500);
        }


        if (props.settingReducer.userCompanyDetails.data) {
            let dt = props.settingReducer.userCompanyDetails.data;
            this.setState({
                onlySystemAdminCreate: dt.allowsystemadmin ? dt.allowsystemadmin : false,
                comp_name: dt.displayname
            })
        }
    }
    handleDialogClose = () => {
        this.setState({
            openEditDialog: false, confirmationDialog: false
        })
    }
    handleDeleteUser = (userid) => {
        this.setState({
            delete_id: userid,
            confirmationDialog: true
        });
    };

    handleConfirmationResponse = () => {
        this.deleteUser();
        this.handleDialogClose();
        this.fetchSettingUserList();
    };

    fetchSettingUserList = () => {
        this.setState({
            isDataLoading: true
        });
        setTimeout(() => {
            this.props.getUserList(this.props.apolloClient.client)
        }, 1500);
    }

    deleteUser = () => {
        let send_data = ({ userid: [this.state.delete_id] })
        this.props.inviteUserDelete(send_data);
    };

    handleEditUser = (value) => {
        this.setState({ userDataEdit: value, shouldOpenCreateDialog: true })
    }
    setSelection = (val) => {
        this.setState({ selection: val });
    }

    handleLeadDialogClose = () => {
        this.setState({ shouldOpenCreateDialog: false });
    };
    multipleDelete = () => {
        let deleteArray = []
        this.state.selection.map(val => {
            deleteArray.push(this.state.UserListArr[val].id)
        }
        )
        if (deleteArray.length >= 1) {
            let send_data = ({ userid: deleteArray })
            this.props.inviteUserDelete(send_data);
        }
        deleteArray = [];
    }
    handleCancle = () => {
        this.setState({ comp_name: this.state.team_name, isEdit: false })
    }
    handleFormSubmit = () => {
        this.updateSettings()
        this.setState({
            isEdit: false
        })
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            isEdit: true
        })
    };
    handleCheckBoxChange = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        }, () => this.updateSettings())
    };
    updateSettings = () => {
        let req = {
            allowsystemadmin: this.state.onlySystemAdminCreate,
            companyname: this.state.comp_name
        }
        this.props.companyUpdate(this.props.apolloClient.client, req)
    }
    makeSystemAdmin = (event, userdata) => {
        this.setState({
            isDataLoading: true
        })
        let teamdata = userdata.teamlist
        let data = [];
        if (teamdata.length > 0) {
            teamdata.map((v, i) => {
                data.push({ teamid: v.team.id, ismanager: v.teammanager })
            })
        }
        const req = ({
            name: userdata.name,
            email: userdata.email,
            googlesync: userdata.googlesync,
            systemadmin: userdata.systemadmin === "0" ? "1" : "0",
            title: userdata.title,
            dataexport: userdata.dataexport,
            dropbox: userdata.dropbox,
            companyid: userdata.companyId,
            teamdata: data,
            deleteteam: [],
        });
        let edit_user_id = ({ userid: userdata.id });
        let newstate = Object.assign(req, edit_user_id);
        console.log(newstate)
        this.props.saveEditUser(newstate);
        this.fetchSettingUserList();

    };

    handleCurrentPage = (page) => {
        this.setState({
            currentPage: page,
            selection: [],
        })

        let params_for_pagechange = {
            limit: this.state.limit,
            pageno: page,
        }

        this.props.getAllUser(this.props.apolloClient.client, params_for_pagechange);
    }
    handlePageSize = (size) => {

        this.setState({
            limit: size,
            currentPage: 0,
            selection: [],
        })

        let params_for_pagesize = {
            limit: size,
            pageno: 1,
        }
        this.props.getAllUser(this.props.apolloClient.client, params_for_pagesize);
    }

    handleUserLogin = (userdata) => {
        let data = { token: userdata.sessiontoken }
        let r = Server.WebURL + 'openWeb/' + JSON.stringify(data);
        window.open(r, '_blank');

        // let windows1 = window.open('https://dev.kanhacrm.xyz/', "__blank");
        // windows1.localStorage.removeItem('jwt_token');
        // windows1.localStorage.removeItem('user_data_session');
        // windows1.localStorage.setItem("jwt_token", userdata.sessiontoken);
        // windows1.localStorage.setItem("user_data_session", JSON.stringify(userdata));
    }

    render() {
        let { shouldOpenCreateDialog, minimizeLeadDialog, FullCreateLeadDialog } = this.state;
        let { confirmationDialog } = this.state;
        let userdata = JSON.parse(localStorage.getItem('user_data_session_admin'));
        const { value } = this.state;
        const columns = [
            {
                name: "name",
                title: "Name"
            },
            {
                name: "email",
                title: "Email"
            },
            {
                name: "company",
                title: "Company"
            },
            {
                name: "actions",
                title: "Actions"
            },
        ];

        let rows = [];
        if (this.state.UserListArr) {
            this.state.UserListArr.map((val) => {
                rows.push({
                    name: (
                        <MaterialGrid container alignItems="center">
                            <MaterialGrid item>
                                <Fab
                                    size="small"
                                    aria-label="Task"
                                    className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                                >
                                    {val.profileimage ?
                                        <Avatar className="bg-body" src={val.profileimage}></Avatar>
                                        :
                                        <Avatar className="bg-body" >
                                            <Icon className="activity_icon_normal">person</Icon>
                                        </Avatar>
                                    }
                                </Fab>
                            </MaterialGrid>
                            <MaterialGrid item>
                                <h5 className=" pl-10 " style={{ maxWidth: "235px" }} title={val.name}>
                                    {/* <Link
                                        to={"/profile/company_user/" + val.id}
                                        className="company_user_name"> */}
                                    {val.name.length > 25 ? val.name.substring(0, 25) + "..." : val.name}
                                    {/* </Link> */}
                                </h5>
                            </MaterialGrid>
                        </MaterialGrid>
                    ),
                    email: (
                        <MaterialGrid container alignItems="center">
                            <MaterialGrid item>
                                <p className="m-0 p-0" title={val.email}>
                                    {val.email.length > 35 ? val.email.substring(0, 35) + "..." : val.email}
                                </p>
                            </MaterialGrid>
                        </MaterialGrid>
                    ),
                    company: (
                        <MaterialGrid container alignItems="center">
                            <MaterialGrid item>
                                <p className="m-0 p-0 ">{val.__company__.name}</p>
                            </MaterialGrid>
                        </MaterialGrid>
                    ),
                    actions: (<Button variant="contained" color="primary" onClick={() => this.handleUserLogin(val)} aria-label="">Login{/* As {val.name} */}
                    </Button>)
                })
            })
        }
        const toolbarTemplate = params => {
            return (
                <div className="m-10">

                </div>
            );
        };
        const columnWidths = [
            { columnName: "name", width: 300 },
            { columnName: "email", width: 300 },
            { columnName: "company", width: 200 },
            { columnName: "actions", width: 150 },

        ]
        return (
            <>
                <AdminSidebarMenu />
                <div style={{ overflow: 'auto', height: '100%' }} className="v_scrollI">
                    <ValidatorForm noValidate instantValidate={false} ref="form" onSubmit={this.handleFormSubmit}>
                        <Typography variant="h5" color="inherit" className="top_typograpy">
                            Users
                        {/* ({rows.length}) */}
                        </Typography>
                        {/* <div style={{ marginBottom: '20px' }}>
                        <span>
                            Manage your company name and invite team members
                    </span>
                    </div> */}
                        {/* {
                        (this.props.authReducer.userRole.id !== 3) &&
                        <Card className="m-10">
                            <div className="lead_upcoming">
                                <div className="box_portlet p-20">
                                    <MaterialGrid container spacing={2} item xs={4}>
                                        <InputLabel>Company Name</InputLabel>
                                        <TextValidator
                                            className="w-100 mb-16"
                                            required={true}
                                            placeholder="Enter Company Name"
                                            type="text"
                                            name="comp_name"
                                            onChange={this.handleChange}
                                            value={this.state.comp_name}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                            autoComplete='off'
                                        />
                                    </MaterialGrid>
                                    {this.state.isEdit &&
                                        <MaterialGrid container spacing={2} xs={4}>
                                            <DialogActions className="dialog-action">
                                                <Button variant="contained" onClick={this.handleCancle}>
                                                    Cancel</Button>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    color="primary"
                                                >Save</Button>
                                            </DialogActions>
                                        </MaterialGrid>
                                    }
                                </div>
                            </div>
                        </Card>
                    } */}
                        {/* <Card className="m-10">
                        <div className="lead_upcoming">
                            <div className="box_portlet p-20">
                                <FormControlLabel className="font-size-14"
                                    control={
                                        <Checkbox
                                            checked={this.state.onlySystemAdminCreate}
                                            name="onlySystemAdminCreate"
                                            disabled={this.props.authReducer.userRole.id === 3 ? true : false}
                                            onChange={this.handleCheckBoxChange}
                                            color="primary"
                                        />
                                    }
                                    label={<span className="font-size-14">
                                        Only allow System Admins to invite new users
                                        </span>}
                                />
                            </div>
                        </div>
                    </Card> */}
                        <div>
                            <MaterialGrid className="pb-4 pl-10 pr-10 pt-4"
                                container justify="space-between">
                                {/* <span className="font-size-18 pt-4"></span> */}
                                {/* {
                                (this.props.authReducer.userRole.id !== 3 ||
                                    (this.props.authReducer.userRole.id === 3 && this.state.onlySystemAdminCreate !== true)) ?
                                    < Button color="primary"
                                        onClick={() => this.setState({ shouldOpenCreateDialog: true, userDataEdit: null })}
                                        variant="contained"
                                        size="small">
                                        Invite New User
                                    </Button>
                                    :
                                    <Button
                                        variant="contained"
                                        disabled={true}
                                        size="small">
                                        Invite New User
                                    </Button>
                            } */}
                            </MaterialGrid>
                        </div>
                        <Card className="m-10">
                            <div className="v_scroll user_list_table_wrapper position-relative">
                                <div className="leads_list_table">
                                    <Grid rows={rows} columns={columns}>
                                        <SelectionState
                                        // selection={this.state.selection}
                                        // onSelectionChange={this.setSelection}
                                        />
                                        <IntegratedSelection />
                                        <VirtualTable />
                                        <SearchState defaultValue="" />
                                        <IntegratedFiltering />
                                        {/* <IntegratedSorting /> */}
                                        <Table />
                                        {/* <TableSelection   /> */}
                                        <TableColumnResizing
                                            defaultColumnWidths={columnWidths}
                                        />
                                        <PagingState
                                            currentPage={this.state.currentPage}
                                            pageSize={this.state.limit}
                                            //defaultCurrentPage={defaultCurrentpage}
                                            onCurrentPageChange={(page) => this.handleCurrentPage(page)}
                                            onPageSizeChange={(size) => this.handlePageSize(size)}
                                        />
                                        <CustomPaging
                                            totalCount={this.state.totalCount}
                                        />
                                        <PagingPanel
                                            pageSizes={[5, 10, 15]}
                                        />
                                        <TableHeaderRow />
                                        <Toolbar rootComponent={toolbarTemplate} />
                                        <SearchPanel />
                                    </Grid>
                                </div>
                            </div>

                            {confirmationDialog && (
                                <ConfirmationDialog
                                    open={confirmationDialog}
                                    onConfirmDialogClose={this.handleDialogClose}
                                    onYesClick={this.handleConfirmationResponse}

                                />
                            )}
                            {
                                shouldOpenCreateDialog && (
                                    <CreateNewUser
                                        handleClose={this.handleLeadDialogClose}
                                        open={shouldOpenCreateDialog}
                                        handleMinimize={this.handleLeadDialogMinimize}
                                        handleFull={this.handleLeadDialogFull}
                                        minimizeScreen={minimizeLeadDialog}
                                        fullScreen={FullCreateLeadDialog}
                                        userDataEdit={this.state.userDataEdit}
                                        fetchUserList={this.fetchSettingUserList}
                                    />
                                )
                            }
                            {
                                this.state.isDataLoading &&
                                <Snackbar
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    open={this.state.isDataLoading}
                                    TransitionComponent={Fade}
                                    message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                                    variant={'success'}
                                />
                            }
                        </Card >
                    </ValidatorForm>
                </div >
            </>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    authReducer: state.authReducer

});


const mapDispatchToProps = dispatch => {
    return {

        getAllUser: (data, request) => dispatch(actionCreators.getAllUser(data, request)),

        saveEditUser: (data) => dispatch(actionCreators.saveEditUser(data)),

        getCompanyDetail: (client, req) => dispatch(actionCreators.getCompanyDetail(client, req)),
        companyUpdate: (client, req) => dispatch(actionCreators.companyUpdate(client, req)),
        getUserList: (data) => dispatch(actionCreators.getUserList(data)),
        getSettingUserList: (sendData) => dispatch(actionCreators.getSettingUserList(sendData)),
        inviteUserDelete: (delete_data) => dispatch(actionCreators.inviteUserDelete(delete_data))
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(userManagement));
