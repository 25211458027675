import React, { Component } from "react";
import { IconButton, Icon, Grid, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar, Card } from "@material-ui/core";

import { connect } from "react-redux";
import { compose } from 'redux';

import LatestActivity from "./LatestActivity"
import MyOpenTasks from "../SummaryTab/MyOpenTasks"
import ActivityLeaderBoard from "./ActivitiesLeaderboard"


class ActivityDashboard extends Component {
  state = {
  };
  render() {
    return (
      <React.Fragment>
          <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActivityLeaderBoard />
          </Grid>
          <Grid item xs={6}>
              <MyOpenTasks />
            </Grid>
            <Grid item xs={6}>
              <LatestActivity />
            </Grid>
          </Grid>        
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ActivityDashboard);