import React, { Component } from "react";
import { Grid, Fab, } from "@material-ui/core";

class SystemLog extends Component {
    render() {
        let { log, } = this.props;
        return (
            <div className="box_portlet">
                <div className="box_body">
                    <div className="lead_pl p-0">
                        <div className="font-size-14">
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Fab className="small-size overflow-hidden mr-8" color="secondary" size="small">
                                        <h5 className={"text-white m-0 font-weight-normal  avatar_circle color_" + log.color}>
                                            {log.color.toUpperCase()}
                                        </h5>
                                    </Fab>
                                </Grid>
                                <Grid item md={10}>
                                    <span className="font-weight-500" dangerouslySetInnerHTML={{ __html: log.template }} />
                                    <p className="mt-4 mb-4 font-size-13 text-hint">{log.date}, {log.time}</p>
                                    {/* <p className="ml-40 font-size-14">{log.text}</p> */}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SystemLog