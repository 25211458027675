import * as actionTypes from '../actions/actionTypes';
import { updateObject } from "../utility";
const initialState = {
  leadList: [],
  leadLogList: [],
  leadLogSidebarList: [],
  leadListOrder: [],
  leadFieldData: [],
  leadFilterListArray: [],
  lead_column_order_extra: [{ statusupdatedate:1001,statusduration:1002,lastcontacted:1003,interactions:1004,inactivedays:1005}],
}

const leadreducer = (state = initialState, action) => {
  switch (action.type) {
    
    case actionTypes.GET_LEAD_LIST:
      return updateObject(state, { leadList: action.val })
    case actionTypes.GET_LEAD_FILTER_LIST:
      return updateObject(state, { leadList: action.val })
    case actionTypes.GET_LEAD_LOG_LIST:
      return updateObject(state, { leadLogList: action.val })
    case actionTypes.GET_LEAD_LOG_SIDEBAR_LIST:
      return updateObject(state, { leadLogSidebarList: action.val })
    case actionTypes.GET_LEAD_LIST_ORDER:
      return updateObject(state, { leadListOrder: action.val })
   	case actionTypes.LEAD_FIELD_DATA:
      return updateObject(state, { leadFieldData: action.val })
    case actionTypes.GET_LEADFILTER_LIST:
      return updateObject(state, { leadFilterListArray: action.val })
   
      
    default:
      return state;
  }
};
export default leadreducer;
