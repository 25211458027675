import * as actionTypes from '../actions/actionTypes';
import { updateObject } from "../utility";
const initialState = {
  reportPipelineSummary: [],
  salesForecastSummary: [],
  reportsalesPerformance: [],
  salesPerformanceSummary: [],
  reportsalesbysource: [],
  reportactivityleaderboard: [],
  conversionFunnelReport: [],
  pipelinereportfilterlist: [],
  forecastreportfilterlist: [],
  performancereportfilterlist: [],
  performancesummaryreportfilterlist: [],
  reportfilterlist: [],
  opentaskreportdata: [],
  activitydashboardreportlist: [],
  UserDashboardId:0
}

const reportReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_REPORT_PIPELINE_SUMMARY:
      return updateObject(state, { reportPipelineSummary: action.val })

    case actionTypes.GET_REPORT_SALES_FORECAST_SUMMARY:
      return updateObject(state, { salesForecastSummary: action.val })

    case actionTypes.GET_REPORT_SALES_PERFORMANCE_SUMMARY:
      return updateObject(state, { salesPerformanceSummary: action.val })

    case actionTypes.GET_REPORT_SALES_PERFORMANCE:
      return updateObject(state, { reportsalesPerformance: action.val })

    case actionTypes.GET_REPORT_SALES_BY_SOURCE:
      return updateObject(state, { reportsalesbysource: action.val })

    case actionTypes.ACTIVITY_LEADERBOARD_DATA:
      return updateObject(state, { reportactivityleaderboard: action.val })

    case actionTypes.CONVERTION_FUNNEL_DATA:
      return updateObject(state, { conversionFunnelReport: action.val })


    case actionTypes.OPEN_TASK_REPORT:
      return updateObject(state, { opentaskreportdata: action.val })

    case actionTypes.ACTIVITY_DASHBOARD_REPORT_LIST:
      return updateObject(state, { activitydashboardreportlist: action.val })

    case actionTypes.GET_REPORT_SALES_BY_SOURCE:
      return updateObject(state, { activitydashboardreportlist: action.val })

    case actionTypes.GET_PIPELINE_REPORT_FILTER_LIST:
      return updateObject(state, { pipelinereportfilterlist: action.val })

    case actionTypes.GET_FORECAST_REPORT_FILTER_LIST:
      return updateObject(state, { forecastreportfilterlist: action.val })

    case actionTypes.GET_PERFORMANCE_REPORT_FILTER_LIST:
      return updateObject(state, { performancereportfilterlist: action.val })

    case actionTypes.GET_PERFORMANCE_SUMMARY_REPORT_FILTER_LIST:
      return updateObject(state, { performancesummaryreportfilterlist: action.val })

    case actionTypes.DASHBOAD_USER_ID:
      return updateObject(state, { UserDashboardId: action.val })

    default:
      return state;
  }
};
export default reportReducer;
