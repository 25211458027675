import * as actionTypes from '../actions/actionTypes';
import { updateObject } from "../utility";
const initialState = {
  counter: [],
  userData: [],
  userRole: [],
  alluserList: [],
  allcompanyList:[],
  getloginToken:[],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGISTER_USER:
      console.log("---------------");
      const newSate = Object.assign({}, state);
      newSate.userData = action.val;
      return newSate;

    case actionTypes.LOGIN_WITH_EMAIL_AND_PASSWORD:
      return { ...state, userData: action.val };

    case actionTypes.ALL_USERS_LIST_LIST:
      return { ...state, alluserList: action.val };
      
    case actionTypes.ALL_COMPANIES_LIST_LIST:
      return { ...state, allcompanyList: action.val };

    case actionTypes.SET_LOGIN_TOKEN:
      return { ...state, getloginToken: action.val };
      
      





























    case actionTypes.USER_VERIFY:
      console.log("user_verify");
      return { ...state, userData: action.val };

    case actionTypes.FORGOT_PASSWORD_REQUEST:
      console.log("forgot_password_request");
      return { ...state, counter: action.val };

    case actionTypes.FORGOT_PASSWORD:
      console.log("forgot_password_request");
      return { ...state, counter: action.val };

    case actionTypes.USER_LOGIN:
      console.log("return user_login_data");
      return { ...state, counter: action.val };

    case actionTypes.DECREMENT:
      console.log("herere")
      return updateObject(state, { counter: state.counter - action.val })

    case actionTypes.RESEND_EMAIL:
      console.log("herere")
      return updateObject(state, { userData: state.counter })


    case actionTypes.USER_ROLE:
      return updateObject(state, { userRole: action.val })
    default:
      return state;
  }
};
export default reducer;