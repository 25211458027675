export default {
   // API: process.env.REACT_APP_API,
   
   // API: "https://kanhasoftcrm.xyz:1236/",  // staging - commented july 2024
   // API: "https://restapi.idrivecrm.com/",  // client
   API: "https://kanhasoftcrm.club:1236/", // new - added july 2024

   // GraphApi: "https://dev.kanhasoftcrm.xyz:4002/graphql",//staging URL - commented july 2024
   // GraphApi:"https://api.idrivecrm.com/graphql", //Client new
   GraphApi: "https://kanhasoftcrm.club:4002/graphql",// New - added july 2024

   // Socket_URI: `wss://dev.kanhasoftcrm.xyz:4002/graphql`, //staging URL - commented july 2024
   Socket_URI: `wss://kanhasoftcrm.club:4002/graphql`, //New - added july 2024
   // Socket_URI: `wss://api.idrivecrm.com/graphql`, //Client new

   Intregation_URI : "https://devcrmpython.kanhasoftcrm.xyz/", // Dev
   // Intregation_URI : "https://crmpython.idrivecrm.com/", // Client

   BCCINBOX: "@inbox.kanhasoftcrm.xyz",
   // BCCINBOX: "@inbox.idrivecrm.com"

   WebURL: "https://dev.kanhasoftcrm.club/" // new added - july 2024
}