import React, { Component } from "react";
import {  Fab, Tooltip,withStyles } from "@material-ui/core";

const TooltipNew = withStyles((theme) => ({
    tooltip: {
        fontSize: 14,
    },
}))(Tooltip);

class FilterLogoCommon extends Component {
    render() {
        var str, matches, acronym = ""
        if (JSON.parse(localStorage.getItem('user_data_session_admin')) && JSON.parse(localStorage.getItem('user_data_session_admin')).name) {
            str = JSON.parse(localStorage.getItem('user_data_session_admin')).name
            matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            acronym = matches.join(''); // JSON
        }
        return (
            <div className="box_header">
                <div className="box_label"><h3 className="box_title">{this.props.headerKey}</h3></div>
                <div className="box_tools">
                    <TooltipNew title={str}>
                        <Fab className="bg-dark small-size overflow-hidden" size="small">
                            <h5 className="text-primary m-0 font-weight-normal">
                                {acronym}
                            </h5>
                        </Fab>
                    </TooltipNew>
                </div>
            </div>
        );
    }
}

export default FilterLogoCommon