import * as actionTypes from '../actions/actionTypes';
import { updateObject } from "../utility";
const initialState = {
  taskTypeList : [],
  taskList : [],
  taskLogList: [],
  taskListOrder : [],
  taskFieldData : [],
  // task_column_order_extra: [{ lastcontacted: 1003, interactions: 1004, inactivedays: 1005 }],
  taskFilterListArray : [],
}

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case actionTypes.GET_TASK_TYPE:
      return updateObject(state, { taskTypeList: action.val })
    case actionTypes.GET_TASK_FILTER_LIST:
      return updateObject(state, { taskList: action.val })
    case actionTypes.GET_TASK_LIST_ORDER:
      return updateObject(state, { taskListOrder: action.val })
    case actionTypes.GET_TASK_LOG_LIST:
      return updateObject(state, { taskLogList: action.val })
    case actionTypes.TASK_FIELD_DATA:
      return updateObject(state, { taskFieldData: action.val })
    case actionTypes.GET_TASKFILTER_LIST:
      return updateObject(state, { taskFilterListArray: action.val })     
         
    default:
      return state;
  }
};
export default taskReducer;
