import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Snackbar, Fade, CircularProgress, Icon, Card, IconButton, Dialog,
    DialogActions, InputLabel, DialogTitle, Fab, Avatar, Checkbox,
    Typography, FormControlLabel, MenuItem, Tabs, Box, Tab, TableContainer, TableHead, DialogContent, TableRow, TableCell, TableBody, withStyles
} from "@material-ui/core";
import { connect } from "react-redux";
import ConfirmationDialog from './ConfirmationDialog';
import CreateNewUser from './CreateNewUser';
import AdminSidebarMenu from "../Layout/AdminSidebarMenu";
import Server from "../../Common/Server";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import {
    Grid,
    Table,
    TableHeaderRow,
    Toolbar,
    VirtualTable,
    TableSelection, 
    SearchPanel,
    PagingPanel,
    TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    SearchState,
    IntegratedSorting,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
    PagingState,
    IntegratedPaging,
    TableColumnResizing,
    CustomPaging,
    RowDetailState,
} from '@devexpress/dx-react-grid';
import * as actionCreators from '../../store/actions/index';
import FontAwesome from "react-fontawesome";
import { default as MaterialGrid } from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { TextValidator, ValidatorForm, SelectValidator } from "react-material-ui-form-validator";

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.5rem",
        color: 'rgba(0, 0, 0, 0.6)'
    }
}))(Icon);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={2}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}
class CompanyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            CompanyListArr: [],
            openEditDialog: false,
            selectoptions: [],
            confirmationDialog: false,
            delete_id: '',
            shouldOpenCreateDialog: false,
            selection: [],
            isDataLoading: true,
            comp_name: "",
            isEdit: false,
            onlySystemAdminCreate: false,
            //table for main list
            limit: 10,
            currentPage: 0,
            totalCount: 0,

            limit2: 10,
            currentPage2: 0,
            totalCount2: 0,

            limit3: 10,
            currentPage3: 0,
            totalCount3: 0,

            dateopen: false,
            date_filter: 10,
            LoginToken: "",
            dateformat: 'MM/DD/YYYY',
            selectedDate: { from: moment().startOf("month").format('YYYY-MM-DD'), to: moment().endOf("month").format('YYYY-MM-DD') },
            selectedDatelabel: "",
            definedRanges: [
                {
                    id: 1,
                    label: "Last 24 Hours",
                    startDate: moment().format("YYYY-MM-DD"),
                    endDate: moment().subtract(24, "hours").format("YYYY-MM-DD")
                },

                {
                    id: 2,
                    label: "Today",
                    startDate: moment().format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },

                {
                    id: 3,
                    label: "Yesterday",
                    startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                    endDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
                },

                {
                    id: 4,
                    label: "This Week",
                    startDate: moment().day(1).format('YYYY-MM-DD'),
                    endDate: moment().day(7).format('YYYY-MM-DD')
                },

                {
                    id: 5,
                    label: "This Month",
                    startDate: moment().startOf("month").format('YYYY-MM-DD'),
                    endDate: moment().endOf("month").format('YYYY-MM-DD')
                },

                {
                    id: 6,
                    label: "This Quarter",
                    startDate: moment().startOf('quarter').format('YYYY-MM-DD'),
                    endDate: moment().endOf('quarter').format('YYYY-MM-DD')
                },

                {
                    id: 7,
                    label: "This Year",
                    startDate: moment().startOf('year').format('YYYY-MM-DD'),
                    endDate: moment().endOf('year').format('YYYY-MM-DD')
                },

                {
                    id: 8,
                    label: "Last 7 days",
                    startDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },

                {
                    id: 9,
                    label: "Last 30 days",
                    startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },
                {
                    id: 10,
                    label: "All Time",
                    startDate: "",
                    endDate: ""
                },
            ],
            fromdate: moment().startOf("month").format('YYYY-MM-DD'),
            todate: moment().endOf('month').format('YYYY-MM-DD'),
            TabValue: 0,
        }
    }

    componentDidMount() {
        let userdata = JSON.parse(localStorage.getItem('user_data_session_admin'));
        var req = {
            companyid: userdata.companyId
        }
        // this.props.getCompanyDetail(this.props.apolloClient.client, req)

        let dateRanges = this.state.definedRanges;
        let dateskey = this.findWithAttr(dateRanges, 'id', this.state.date_filter);

        this.props.getUserList(this.props.apolloClient.client);

        let companyparams = { limit: parseInt(this.state.limit), pageno: parseInt(this.state.currentPage), fromdate: dateRanges[dateskey].startDate, todate: dateRanges[dateskey].endDate };
        this.props.getCompanyList(this.props.apolloClient.client, companyparams);
    }

    componentWillReceiveProps(props) {

        if (props.authReducer.allcompanyList && props.authReducer.allcompanyList.data && props.authReducer.allcompanyList.data.data) {
            let CompanyListArr = props.authReducer.allcompanyList.data.data.user;
            let totalRecord = props.authReducer.allcompanyList.data.data.totalrecord;
            this.setState({ CompanyListArr, isDataLoading: false, totalCount: totalRecord })


        } else {
            setTimeout(() => {
                this.setState({ isDataLoading: false })
            }, 1500);
        }
        if (props.authReducer.getloginToken && props.authReducer.getloginToken.data && this.state.LoginToke != props.authReducer.getloginToken.data) {
            let logintoken = props.authReducer.getloginToken.data;
            this.setState({ LoginToke: logintoken })

            let data = { token: logintoken }
            let r = Server.WebURL + 'openWeb/' + JSON.stringify(data);
            window.open(r, '_blank');
            // console.log(props.authReducer.getloginToken,'props.authReducer.getloginToken');
        }

        if (props.settingReducer.userCompanyDetails.data) {
            let dt = props.settingReducer.userCompanyDetails.data;
            this.setState({
                onlySystemAdminCreate: dt.allowsystemadmin ? dt.allowsystemadmin : false,
                comp_name: dt.displayname
            })
        }
    }

    handleDateChange = (event) => {
        // console.log(event.target.value);

        let dateRanges = this.state.definedRanges;
        let dateskey = this.findWithAttr(dateRanges, 'id', event.target.value);
        this.setState({ date_filter: event.target.value });
        let companyparams = { limit: parseInt(this.state.limit), pageno: parseInt(this.state.currentPage), fromdate: dateRanges[dateskey].startDate, todate: dateRanges[dateskey].endDate };
        this.props.getCompanyList(this.props.apolloClient.client, companyparams);
    }

    handleDialogClose = () => {
        this.setState({
            openEditDialog: false, confirmationDialog: false
        })
    }

    handleDeleteUser = (userid) => {
        this.setState({
            delete_id: userid,
            confirmationDialog: true
        });
    };

    handleConfirmationResponse = () => {
        this.deleteUser();
        this.handleDialogClose();
        this.fetchSettingUserList();
    };

    fetchSettingUserList = () => {
        this.setState({
            isDataLoading: true
        });
        setTimeout(() => {
            this.props.getUserList(this.props.apolloClient.client)
        }, 1500);
    }

    deleteUser = () => {
        let send_data = ({ userid: [this.state.delete_id] })
        this.props.inviteUserDelete(send_data);
    };

    handleEditUser = (value) => {
        this.setState({ userDataEdit: value, shouldOpenCreateDialog: true })
    }
    setSelection = (val) => {
        this.setState({ selection: val });
    }

    handleLeadDialogClose = () => {
        this.setState({ shouldOpenCreateDialog: false });
    };

    multipleDelete = () => {
        let deleteArray = []
        this.state.selection.map(val => {
            deleteArray.push(this.state.CompanyListArr[val].id)
        }
        )
        if (deleteArray.length >= 1) {
            let send_data = ({ userid: deleteArray })
            this.props.inviteUserDelete(send_data);
        }
        deleteArray = [];
    }

    handleCancle = () => {
        this.setState({ comp_name: this.state.team_name, isEdit: false })
    }
    handleFormSubmit = () => {
        this.updateSettings()
        this.setState({
            isEdit: false
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            isEdit: true
        })
    };
    handleCheckBoxChange = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        }, () => this.updateSettings())
    };
    updateSettings = () => {
        let req = {
            allowsystemadmin: this.state.onlySystemAdminCreate,
            companyname: this.state.comp_name
        }
        this.props.companyUpdate(this.props.apolloClient.client, req)
    }
    makeSystemAdmin = (event, userdata) => {
        this.setState({
            isDataLoading: true
        })
        let teamdata = userdata.teamlist
        let data = [];
        if (teamdata.length > 0) {
            teamdata.map((v, i) => {
                data.push({ teamid: v.team.id, ismanager: v.teammanager })
            })
        }
        const req = ({
            name: userdata.name,
            email: userdata.email,
            googlesync: userdata.googlesync,
            systemadmin: userdata.systemadmin === "0" ? "1" : "0",
            title: userdata.title,
            dataexport: userdata.dataexport,
            dropbox: userdata.dropbox,
            companyid: userdata.companyId,
            teamdata: data,
            deleteteam: [],
        });
        let edit_user_id = ({ userid: userdata.id });
        let newstate = Object.assign(req, edit_user_id);
        console.log(newstate)
        this.props.saveEditUser(newstate);
        this.fetchSettingUserList();

    };

    handleCurrentPage = (page) => {
        this.setState({
            currentPage: page,
            selection: [],
        })

        let params_for_pagechange = {
            limit: this.state.limit,
            pageno: page,
        }

        this.props.getCompanyList(this.props.apolloClient.client, params_for_pagechange);
    }

    handlePageSize = (size) => {

        this.setState({
            limit: size,
            currentPage: 0,
            selection: [],
        })

        let params_for_pagesize = {
            limit: size,
            pageno: 1,
        }
        this.props.getCompanyList(this.props.apolloClient.client, params_for_pagesize);
    }

    handleCurrentPage2 = (page) => {
        this.setState({
            currentPage2: page,
            selection: [],
        })
    }

    handlePageSize2 = (size) => {
        this.setState({
            limit2: size,
            currentPage2: 0,
            selection: [],
        })
    }

    handleCurrentPage3 = (page) => {
        this.setState({
            currentPage2: page,
            selection: [],
        })
    }

    handlePageSize3 = (size) => {
        this.setState({
            limit2: size,
            currentPage2: 0,
            selection: [],
        })
    }


    handleUserLogin = (userdata) => {
        let params = { email: userdata.email };
        if (userdata.sessiontoken == null) {
            this.props.GetLoginToken(params);
        } else {
            let data = { token: userdata.sessiontoken }
            let r = Server.WebURL + 'openWeb/' + JSON.stringify(data);
            window.open(r, '_blank');
        }

        // let windows1 = window.open('https://dev.kanhacrm.xyz/', "__blank");
        // windows1.localStorage.removeItem('jwt_token');
        // windows1.localStorage.removeItem('user_data_session');
        // windows1.localStorage.setItem("jwt_token", userdata.sessiontoken);
        // windows1.localStorage.setItem("user_data_session", JSON.stringify(userdata));
    }



    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ TabValue: newValue });
    }

    onActionDialogClose = () => {
        this.setState({ ShouldOpenAction: false });
    }

    render() {
        let { shouldOpenCreateDialog, minimizeLeadDialog, FullCreateLeadDialog } = this.state;
        let { dateopen, confirmationDialog } = this.state;
        let userdata = JSON.parse(localStorage.getItem('user_data_session_admin'));
        const { value } = this.state;

        const columns = [
            {
                name: "totalcount",
                title: "Sr.No"
            },
            {
                name: "name",
                title: "Name"
            },
            {
                name: "companysize",
                title: "Company Size"
            },
            // {
            //     name: "companydisplay",
            //     title: "Company Display"
            // },
            {
                name: "id",
                title: "id"
            },
            {
                name: "actions",
                title: "Action"
            },
        ];

        const columnsforcompanymember = [
            {
                name: "totalcount",
                title: "Sr.No"
            },
            {
                name: "name",
                title: "Name"
            },
            {
                name: "email",
                title: "Email",
                width: 500
            },
            {
                name: "actions",
                title: "Action",
                className : "align_right",
            },
        ];

        const columnsformembers = [
            {
                name: "name",
                title: "Membership Plan"
            },
            {
                name: "price",
                title: "Plan Price"
            },
        ];

        let rows = [];
        let rowsformembers = [];

        if (this.state.CompanyListArr && this.state.CompanyListArr.length) {
            console.log("detai;s : ", this.state.CompanyListArr);
            this.state.CompanyListArr.map((val, index) => {

                rows.push({
                    id: val.id,
                    totalcount: (
                        <p>{index + 1}</p>
                    ),
                    name: (
                        <MaterialGrid container alignItems="center">
                            <MaterialGrid item>
                                <Fab
                                    size="small"
                                    aria-label="Task"
                                    className={`wrap_icons_task_sidebar vert-middle bg-grey_sidebar`}
                                >
                                    {val.profileimage ?
                                        <Avatar className="bg-body" src={val.profileimage} style={{ width: '40px', height: '40px' }} ></Avatar>
                                        :
                                        <Avatar className="bg-body" style={{ width: '40px', height: '40px' }} >
                                            <Icon className="activity_icon_normal">person</Icon>
                                        </Avatar>
                                    }
                                </Fab>
                            </MaterialGrid>
                            <MaterialGrid item>
                                <h5 className="d-inline-flex pl-10">
                                    {/* <Link
                                        to={"/profile/company_user/" + val.id}
                                        className="company_user_name"> */}
                                    {val.name}
                                    {/* </Link> */}
                                </h5>
                            </MaterialGrid>
                        </MaterialGrid>
                    ),
                    companysize: (
                        // <MaterialGrid container alignItems="center">
                        //     <MaterialGrid item>
                        <p className="m-0 p-0 pl-20">{val.userlist ? val.userlist.length : "-"}</p>
                        //     </MaterialGrid>
                        // </MaterialGrid>
                    ),
                    // companydisplay: (
                    //     // <MaterialGrid container alignItems="center">
                    //     //     <MaterialGrid item>
                    //     <p className="m-0 p-0 ">{val.displayname}</p>
                    //     //     </MaterialGrid>
                    //     // </MaterialGrid>
                    // ),
                    actions: (
                        <IconButton size="small" className="ml-4" color="primary" onClick={(e) => this.setState({ ShouldOpenAction: true, userActionId: val, totalCount2: val && val.userlist.length, TabValue: 0, totalCount3: 1 })}>
                            <Icon>remove_red_eye</Icon>
                        </IconButton>
                    )
                })
            })
        }
        let rowsforcompanymember = [];
        // if (this.state.CompanyListArr && this.state.CompanyListArr.length) {..
        this.state.userActionId && this.state.userActionId.userlist.length > 0 && this.state.userActionId.userlist.map((val, index) => {
            rowsforcompanymember.push({
                totalcount: (
                    <p>{index + 1}</p>
                ),
                name: (
                    <MaterialGrid container alignItems="center">
                        <MaterialGrid item>
                            <h5 className="d-inline-flex ">
                                {val.name}
                            </h5>
                        </MaterialGrid>
                    </MaterialGrid>
                ),
                email: (
                    <MaterialGrid container alignItems="center">
                        <MaterialGrid item className='mr-10'>
                            <p className="m-0 p-0">{val.email}</p>
                        </MaterialGrid>
                    </MaterialGrid>
                ),
                actions: (
                    <MaterialGrid container alignItems="right">
                        <MaterialGrid className=''>
                            <Button variant="contained" color="primary" onClick={() => this.handleUserLogin(val)} aria-label="">Login</Button>
                        </MaterialGrid>
                    </MaterialGrid>
                )
            })
        })


        if (this.state.CompanyListArr && this.state.CompanyListArr.length && this.state.userActionId) {
            console.log("data", this.state.userActionId.plandetail);
            rowsformembers.push({
                name: (
                    <MaterialGrid container alignItems="center">
                        <MaterialGrid item>
                            <Typography className="font-size-13">{this.state.userActionId.plandetail.__plan__.planname}</Typography>
                            <Typography className="text-hint font-size-13">{this.state.userActionId.plandetail.__plan__.subname}</Typography>
                        </MaterialGrid>
                    </MaterialGrid>
                ),
                price: (
                    <MaterialGrid container alignItems="center">
                        <MaterialGrid item>
                            <Typography className="text-hint font-size-13">${this.state.userActionId.plandetail.__plan__.planprice}</Typography>
                        </MaterialGrid>
                    </MaterialGrid>
                ),
            })

        }


        const RowDetail = ({ row }) => {
            let mainData = this.state.CompanyListArr.find((val) => {
                return val.id === row.id;
            })


            return (
                <>
                    {mainData.plandetail &&
                        <>
                            <tr>
                                <td style={{ width: '100px' }} colSpan="4">
                                    <h6 style={{ marginLeft: "40%" }}
                                        className="mr-50 text-center">Plan Details</h6>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td style={{ width: '100px' }} ></td>
                                <td style={{ width: '500px' }} className="m-0 p-0 pl-10">
                                    <Typography className="font-size-13">{mainData.plandetail.__plan__.planname}</Typography>
                                    <Typography className="text-hint font-size-13">{mainData.plandetail.__plan__.subname}</Typography>
                                </td>
                                <td style={{ width: '0px' }} ></td>
                                <td style={{ width: '400px' }} className="m-0 p-0 pl-10 "></td>
                                <td style={{ width: '400px' }} className="m-0 p-0 pl-10">
                                    <Typography className="font-size-13">Plan Price</Typography>
                                    <Typography className="text-hint font-size-13">${mainData.plandetail.__plan__.planprice}</Typography>
                                </td>
                            </tr>
                        </>
                    }
                    <tr>
                        <td style={{ width: '100px' }} colSpan="4">
                            <h6 style={{ marginLeft: "40%" }}
                                className="mr-50 text-center">Company Members</h6>
                        </td>
                    </tr>
                    {
                        mainData.userlist.length > 0 &&
                        mainData.userlist.map((userval) => (
                            <>
                                <tr>
                                    <td style={{ width: '100px' }} ></td>
                                    <td style={{ width: '400px' }} className="m-0 p-0 pl-10 font-weight-500">
                                        <Typography className="font-size-13">{userval.name}</Typography>
                                    </td>
                                    <td style={{ width: '100px' }} ></td>
                                    <td style={{ width: '400px' }} className="m-0 p-0 pl-10">
                                        <Typography className="font-size-13">{userval.email}</Typography>
                                    </td>
                                    <td style={{ width: '400px' }} className="m-0 pb-10 pl-10">
                                        <Button variant="contained" color="primary" onClick={() => this.handleUserLogin(userval)} aria-label="">Login</Button>
                                    </td>
                                </tr>
                            </>
                        ))
                    }

                </>
            )
        }

        const toolbarTemplate = params => {
            return (
                <div className="m-10">
                </div>
            );
        };
        const columnWidths = [
            { columnName: "id", width: 0 },
            { columnName: "name", width: 280 },
            { columnName: "companysize", width: 280 },
            // { columnName: "companydisplay", width: 280 },
            { columnName: "actions", width: 280 },
            { columnName: "totalcount", width: 200 },
            // totalcount
        ]

        return (
            <>
                <AdminSidebarMenu />
                <div style={{ overflow: 'auto', height: '100%' }} className="v_scrollI">
                    <ValidatorForm noValidate instantValidate={false} ref="form" onSubmit={this.handleFormSubmit}>
                        <div style={{ float: "left" }}>
                            <Typography variant="h5" color="inherit" className="top_typograpy pull-left" style={{ marginLeft: '12px', marginTop: '10px' }}>
                                Companies
                            {/* ({rows.length}) */}
                            </Typography>
                        </div>
                        <div className="pull-right mr-20" style={{ float: "right" }}>
                            <SelectValidator
                                label={'Date Filter'}
                                style={{ width: "200px" }}
                                value={this.state.date_filter}
                                name="date_filter"
                                required={true}
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                                onChange={this.handleDateChange}
                            >
                                {this.state.definedRanges && this.state.definedRanges.map((opt, key) => {
                                    return <MenuItem key={key} className="select-menu-item" value={opt.id}>{opt.label}</MenuItem>
                                })}
                            </SelectValidator>
                        </div>
                        <div>
                            <MaterialGrid className="pb-4 pl-10 pr-10 pt-4"
                                container justify="space-between">
                            </MaterialGrid>
                        </div>
                        <Card className="m-10">
                            <div className="v_scroll user_list_table_wrapper position-relative">
                                <div className="leads_list_table">
                                    <Grid rows={rows} columns={columns}>
                                        <SelectionState
                                        // selection={this.state.selection}
                                        // onSelectionChange={this.setSelection}
                                        />
                                        <IntegratedSelection />
                                        <VirtualTable />
                                        <SearchState defaultValue="" />
                                        <IntegratedFiltering />
                                        {/* <IntegratedSorting /> */}
                                        <RowDetailState
                                            defaultExpandedRowIds={[]}
                                        />
                                        <Table />
                                        {/* <TableSelection   /> */}
                                        <TableColumnResizing
                                            defaultColumnWidths={columnWidths}
                                        />
                                        <PagingState
                                            currentPage={this.state.currentPage}
                                            pageSize={this.state.limit}
                                            //defaultCurrentPage={defaultCurrentpage}
                                            onCurrentPageChange={(page) => this.handleCurrentPage(page)}
                                            onPageSizeChange={(size) => this.handlePageSize(size)}
                                        />
                                        <CustomPaging
                                            totalCount={this.state.totalCount}
                                        />
                                        <PagingPanel
                                            pageSizes={[5, 10, 15]}
                                        />
                                        <TableHeaderRow />
                                        {/* <TableRowDetail
                                            contentComponent={RowDetail}
                                        /> */}
                                        <Toolbar rootComponent={toolbarTemplate} />
                                        <SearchPanel />
                                    </Grid>
                                </div>
                            </div>

                            {confirmationDialog && (
                                <ConfirmationDialog
                                    open={confirmationDialog}
                                    onConfirmDialogClose={this.handleDialogClose}
                                    onYesClick={this.handleConfirmationResponse}
                                />
                            )}
                            {
                                shouldOpenCreateDialog && (
                                    <CreateNewUser
                                        handleClose={this.handleLeadDialogClose}
                                        open={shouldOpenCreateDialog}
                                        handleMinimize={this.handleLeadDialogMinimize}
                                        handleFull={this.handleLeadDialogFull}
                                        minimizeScreen={minimizeLeadDialog}
                                        fullScreen={FullCreateLeadDialog}
                                        userDataEdit={this.state.userDataEdit}
                                        fetchUserList={this.fetchSettingUserList}
                                    />
                                )
                            }
                            {
                                this.state.isDataLoading &&
                                <Snackbar
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    open={this.state.isDataLoading}
                                    TransitionComponent={Fade}
                                    message={<><CircularProgress size={20} style={{ color: 'white' }} /><span style={{ paddingLeft: '6px', color: 'white' }}>{" Loading..."}</span></>}
                                    variant={'success'}
                                />
                            }
                        </Card >
                    </ValidatorForm>
                </div >
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    open={this.state.ShouldOpenAction}
                    onClose={this.onActionDialogClose}
                    disableBackdropClick
                    aria-labelledby="ar-remove-dialog-title"
                >
                    <DialogTitle>
                        <Typography className="p-10" variant="h5" style={{ fontSize: '18px' }} color="inherit">Company Details
                            <div className="clear_icon hover_show" style={{ margin: '-30px 870px' }}>
                                <IconButton aria-label="Delete" size="small" onClick={this.onActionDialogClose}>
                                    <IconSmall style={{ fontSize: '18px' }}>clear</IconSmall>
                                </IconButton>
                            </div>
                        </Typography>
                    </DialogTitle>
                    <DialogContent className='mt-4'>
                        <Tabs
                            value={this.state.TabValue}
                            onChange={this.handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                            className="lead_tabs"
                            variant="scrollable"
                            style={{ padding: '0 60px', width: '600px' }}
                        >
                            <Tab label="Company Members" {...a11yProps(0)} />
                            <Tab label="Membership" {...a11yProps(1)} />
                        </Tabs>
                        <div className="v_scroll position-relative">
                            <TabPanel value={this.state.TabValue} index={0} className="h-100" >
                                <Card style={{ margin: '10px 0 10px 0' }} className="leads_list_table" >
                                    <Grid rows={rowsforcompanymember} columns={columnsforcompanymember}>
                                        <SelectionState />
                                        <IntegratedSelection />
                                        <VirtualTable />
                                        <SearchState defaultValue="" />
                                        <IntegratedFiltering />
                                        <RowDetailState
                                            defaultExpandedRowIds={[]}
                                        />
                                        <Table />
                                        <TableHeaderRow />
                                        <PagingState
                                            currentPage={this.state.currentPage2}
                                            pageSize={this.state.limit2}
                                            onCurrentPageChange={(page) => this.handleCurrentPage2(page)}
                                            onPageSizeChange={(size) => this.handlePageSize2(size)}
                                        />
                                        <CustomPaging
                                            totalCount={this.state.totalCount2}
                                        />
                                        <PagingPanel
                                            pageSizes={[5, 10, 15]}
                                        />
                                    </Grid>
                                </Card>
                            </TabPanel>

                            <TabPanel value={this.state.TabValue} index={1} className="h-100">
                                <Card style={{ margin: '10px 0 10px 0' }}>
                                    <Grid rows={rowsformembers} columns={columnsformembers}>
                                        <SelectionState />
                                        <IntegratedSelection />
                                        <VirtualTable />
                                        <SearchState defaultValue="" />
                                        <IntegratedFiltering />
                                        <RowDetailState
                                            defaultExpandedRowIds={[]}
                                        />
                                        <Table />
                                        <TableHeaderRow />
                                        <PagingState
                                            currentPage={this.state.currentPage3}
                                            pageSize={this.state.limit3}
                                            onCurrentPageChange={(page) => this.handleCurrentPage3(page)}
                                            onPageSizeChange={(size) => this.handlePageSiz3(size)}
                                        />
                                        <CustomPaging
                                            totalCount={this.state.totalCount3}
                                        />
                                        <PagingPanel
                                            pageSizes={[5, 10, 15]}
                                        />
                                    </Grid>
                                </Card>
                            </TabPanel>

                        </div>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    authReducer: state.authReducer

});


const mapDispatchToProps = dispatch => {
    return {
        GetLoginToken: (data) => dispatch(actionCreators.GetLoginToken(data)),
        getCompanyList: (data, request) => dispatch(actionCreators.getCompanyList(data, request)),

        saveEditUser: (data) => dispatch(actionCreators.saveEditUser(data)),
        getCompanyDetail: (client, req) => dispatch(actionCreators.getCompanyDetail(client, req)),
        companyUpdate: (client, req) => dispatch(actionCreators.companyUpdate(client, req)),
        getUserList: (data) => dispatch(actionCreators.getUserList(data)),
        getSettingUserList: (sendData) => dispatch(actionCreators.getSettingUserList(sendData)),
        inviteUserDelete: (delete_data) => dispatch(actionCreators.inviteUserDelete(delete_data))
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(CompanyList));
