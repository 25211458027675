import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Checkbox, List, ListItem, IconButton, InputLabel, Icon, Grid, Menu, withStyles, Typography, MenuItem, Avatar, Fab, Button, Tabs, Tab, Box, Dialog, DialogTitle, DialogActions, Tooltip } from "@material-ui/core";
import moment from 'moment';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { EditorState, convertToRaw, getPlainText, genKey } from 'draft-js';
import createSingleLinePlugin from 'draft-js-single-line-plugin';
import { MegadraftEditor, editorStateFromRaw, editorStateToJSON, MegadraftPlugin, insertDataBlock } from "megadraft-denistsuman";
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import { fromJS } from 'immutable';
import 'draft-js-mention-plugin/lib/plugin.css';
import _ from 'lodash'


let mentions = [];

const IconSmall = withStyles(() => ({
    root: {
        fontSize: "1.3rem",
    }
}))(Icon);

class NoteLog extends Component {
    constructor(props) {
        super(props);

        let editorState = EditorState.createEmpty();
        const myContent = {
            "entityMap": {},
            "blocks": [
                {
                    "key": genKey(),
                    "text": " ",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                }
            ]
        };
        this.state = {
            usersListArray: [],
            isEditLog: false,
            editorState: editorState,
            suggestions: mentions,
            openComment: false,
        }

        this.mentionPlugin = createMentionPlugin({
            mentions,
            entityMutability: 'IMMUTABLE',
            mentionPrefix: '@',
            supportWhitespace: true
        });

        this.singleLinePlugin = createSingleLinePlugin({
            stripEntities: false
        });
    }

    componentWillReceiveProps(props) {

        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
            const usersListArray = props.settingReducer.usersListArray.data;
            mentions = usersListArray;
            this.setState({ usersListArray: usersListArray })
        }
    }
    //----log functions------------
    onChange = (editorState) => {
        const content = editorState.getCurrentContent();
        const textvalue = content.getPlainText('\u0001');

        this.setState({
            textvalue1: textvalue,
            editorState: editorState,
        });
    };

    onSearchChange = ({ value }) => {
        this.setState({
            suggestions: defaultSuggestionsFilter(value, mentions),
        });
    };

    onAddMention = (mention) => {
        let that = this;
        setTimeout(() => {
            this.setState({ editorState: EditorState.moveFocusToEnd(that.state.editorState) })
        }, 10);
    }

    focus = () => {
        this.editor.focus();
    };
    handleEditLog = (id, date, time, text, mentiondetail, mentionid) => {
        let entitymap = [];
        let offset = 0;
        let length = 0;

        mentionid.map((id) => {
            let mentionuserkey = this.findWithAttr(mentions, 'id', id + "");
            if (mentionuserkey != -1) {

                entitymap.push({
                    "type": "mention",
                    "mutability": "SEGMENTED",
                    "data": {
                        "mention": mentions[mentionuserkey]
                    }
                });

            }
        });

        let exampleState = {
            blocks: [{
                "key": "fuq9m",
                "text": text,
                "type": "unstyled",
                "depth": 0,
                "inlineStyleRanges": [],
                "entityRanges": [{ "offset": 0, "length": 0, "key": 0 }],
                "data": {}
            }],
            entityMap: entitymap
        };
        let editorState = editorStateFromRaw(exampleState);
        this.setState({ editorState })
        let date1 = new Date(date + ',' + time);
        this.props.thatAll.saveId(id, date1);

        this.setState({ isEditLog: true, textvalue: text, selectedDate: date1, logid: id });
    }
    saveLogData = () => {
        const content = this.state.editorState.getCurrentContent();
        const textvalue = content.getPlainText('\u0001');
        const raw = JSON.parse(editorStateToJSON(this.state.editorState));
        this.props.thatAll.handleSaveEditLog({ textvalue, raw })
        this.setState({ isEditLog: false });
    }
    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
    toggleComment = (id) => {
        this.setState({ openComment: !this.state.openComment })
        this.props.thatAll.toggleComment(id)
    }
    render() {
        const { MentionSuggestions } = this.mentionPlugin;
        const plugins = [this.singleLinePlugin, this.mentionPlugin];

        let current_logUser = JSON.parse(localStorage.getItem('user_data_session_admin')).email;

        let { log, selectedDate, dateformat, timeZone, commentid, showSelector, isOpenedCommentIds, isEditComment, commentvalue, thatAll, logid } = this.props;
        console.log(log.activitytype,'logloglog')
        return (
            <div className="box_body">
                <div className="lead_pl p-0">
                    <div className="font-size-14 pt-10">
                        <Grid container spacing={1}>
                            <Grid item>
                                <Fab className="small-size overflow-hidden mr-8" color="secondary" size="small">
                                    <h5 className={"text-white m-0 font-weight-normal avatar_circle color_" + log.color}>{log.color.toUpperCase()}</h5>
                                </Fab>
                            </Grid>
                            <Grid item md={10}>
                                <span className="font-weight-500">{log.user}</span>  added a {log.activitytype ? log.activitytype.name : "log"}
                      </Grid>
                        </Grid>
                    </div>

                    
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
});


export default connect(mapStateToProps)(NoteLog);

