import React, { Component } from "react";
import { FormHelperText, Input, FormControl, Divider, Popover, Button, IconButton, Icon, withStyles, Card, DialogContent, Dialog, DialogActions, DialogTitle, Typography, MenuItem, InputLabel, Radio, FormControlLabel, RadioGroup, Badge, Drawer, Tooltip } from "@material-ui/core";
import Grid, { default as MaterialGrid } from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


import { Link } from "react-router-dom";
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";

class SalesForecast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportTitile: "Sales Forecast",
            reportSubTitle: "Amount forecasted to be closed based on the deal amount, win probability, and close date.",
            totalvalue: "$0K",
            totalWinRate: "0%",
            selectedPipline: "0",
            selectedFilter: "1",
            value_type: "1",
            drawerOpen: false,
            dateopen: false,
            dateformat: 'MM/DD/YYYY',
            datetype: "3",
            selectedDate: { from: moment().startOf("month").format('YYYY-MM-DD'), to: moment().endOf('month').format('YYYY-MM-DD') },
            selectedDatelabel: "",
            fromdate: moment().subtract(2, 'months').format('YYYY-MM-DD'),
            todate: moment().format('YYYY-MM-DD'),
            pipelineList: [],
            salesForecastSummary: [],
            fieldDisplay: [],
            dropdownhide: [],
            systemTagsList: [],
            masterActivityTypesList: [],
            usersListArray: [],
            memberid: 0,
        }
    }

    componentDidMount() {
        localStorage.removeItem('selected_report_pipeline_summary_filter_id');
        let fromDate = localStorage.getItem('dashboardFromDate');
        let toDate = localStorage.getItem('dashboardToDate');
        this.props.getPipelines(this.props.apolloClient.client);
        let val = { typeId: 4 }
        this.props.customFieldList(this.props.apolloClient.client, val);
        this.props.getTagsList(this.props.apolloClient.client);
        this.props.getUserList(this.props.apolloClient.client);
        this.props.getActivityTypes(this.props.apolloClient.client);
        this.setState({
            selectedDate: {
                from: moment().startOf("month"),
                to: moment().endOf('month')
            },
            fromdate: fromDate,
            todate: toDate
        }, () => this.updateGraphSettings())
    }

    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;
            this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
        }

        if (props.settingReducer.masterActivityTypesList && props.settingReducer.masterActivityTypesList.is_loaded == true && props.settingReducer.masterActivityTypesList.data) {
            props.settingReducer.masterActivityTypesList.is_loaded = false
            var dataList = props.settingReducer.masterActivityTypesList.data;
            var count = dataList.filter(function (element) {
                if (element.is_disable == true) {
                    return element
                }
            });
            this.setState({
                masterActivityTypesList: dataList,
            });
        }

        if (props.settingReducer.pipelineListArray && props.settingReducer.pipelineListArray.data) {
            this.setState({ pipelineList: props.settingReducer.pipelineListArray.data })
        }

        if (props.settingReducer.usersListArray.data && this.state.usersListArray.length == 0) {
            const usersListArray = props.settingReducer.usersListArray.data;
            this.setState({ usersListArray: usersListArray })
        }

        if (props.reportReducer.salesForecastSummary && props.reportReducer.salesForecastSummary.data) {
            let salesForecastSummary = props.reportReducer.salesForecastSummary.data.data

            let totalweight = 0;
            let totalwon = 0;
            if (salesForecastSummary.weight.length > 0) {
                salesForecastSummary.weight.map((weight) => {
                    totalweight += weight[1];
                })
            }
            if (salesForecastSummary.won.length > 0) {
                salesForecastSummary.won.map((won) => {
                    totalwon += won[1];
                })
            }
            this.setState({ salesForecastSummary, totalvalue: "$" + ((salesForecastSummary.totaloppamount && salesForecastSummary.totaloppamount > 0) ? (salesForecastSummary.totaloppamount) : 0) });
        }

        if (props.settingReducer.opportunitiesCustomFieldList.data) {
            const customFields = props.settingReducer.opportunitiesCustomFieldList.data;
            this.setState({ fieldsList: customFields })
        }
        if (props.reportReducer.UserDashboardId) {
            if (this.state.memberid !== props.reportReducer.UserDashboardId) {
              {
                this.setState({
                  memberid: props.reportReducer.UserDashboardId
                }, () => this.updateGraphSettings())
              }
            }
          }
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }
    handlePiplineChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => this.updateGraphSettings())
    }
    componentDidUpdate(PrevProps) {
        if (PrevProps.reportReducer.UserDashboardId !== this.props.reportReducer.UserDashboardId) {
            this.setState({
                memberid: this.props.reportReducer.UserDashboardId
            }, () => this.updateGraphSettings())
        }
    }
    updateGraphSettings = () => {
        let forecastparams = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: this.state.fromdate,
            todate: this.state.todate,
            datetype: parseFloat(this.state.datetype),
            memberid: parseFloat(this.state.memberid)
        };
        this.props.salesForecastSummary(this.props.apolloClient.client, forecastparams);
    }
    render() {
        let { isOpenedFiltersDefault, filterlist, salesForecastSummary, pipelineList, reportTitile, reportSubTitle, dateopen, selectedFilter, totalvalue, drawerOpen, totalWinRate, selectedPipline, value_type, datetype } = this.state;
        const options = {
            chart: {
                type: 'column'
            },
            title: "",
            subtitle: "",
            xAxis: {
                type: 'datetime',
                crosshair: true,

            },
            yAxis: {
                title: { text: null },
                min: 0,
                labels: {
                    formatter: function () {
                        return '$' + this.value;
                    }
                }
            },
            tooltip: {
                headerFormat: '<b><span style="font-size:14px">{point.key}</span></b><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Forecasted (Weighted)',
                color: '#b8e0ba',
                data: salesForecastSummary.weight
            }, {
                name: 'Won',
                color: '#50ae55',
                data: salesForecastSummary.won
            }]
        }

        return (
            <Card elevation={3} className="dashboard_card mb-10">
                <div className="report_card">
                    <div className="report_header">
                        <Grid container justify="space-between">
                            <h3 className="text-primary">{reportTitile}</h3>
                            <Link className="viewmore text_link" to="/reports/sales-forecast/">View Full Report
                            </Link>
                        </Grid>
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                            <div className="number_label">
                                <div>{totalvalue}</div>
                                <div className=" p-4">Forecast </div>
                                <Tooltip title="Your sales forecast based on Opportunity close date" arrow>
                                    <Icon>info</Icon>
                                </Tooltip>
                            </div>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <div className="report_filter">
                                <InputLabel id="Pipeline-select-helper-label">Pipeline</InputLabel>
                                <Select
                                    className="w-200"
                                    labelId="Pipeline-select-label"
                                    name="selectedPipline"
                                    id="Pipeline"
                                    value={selectedPipline}
                                    onChange={this.handlePiplineChange}
                                >
                                    <MenuItem value={"0"}>All</MenuItem>
                                    {pipelineList && pipelineList.map((pipeline,i) => (
                                        <MenuItem key={i} value={pipeline.id}>{pipeline.name}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </Grid>
                    </div>
                    <div className="report_content">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    reportReducer: state.reportReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
});
const mapDispatchToProps = dispatch => {
    return {

        getPipelines: (client) => dispatch(actions.getPipelines(client)),
        getTagsList: (client) => dispatch(actions.getTagsList(client)),
        getUserList: (client) => dispatch(actions.getUserList(client)),
        getActivityTypes: (client) => dispatch(actions.getActivityTypes(client)),
        customFieldList: (client, value) => dispatch(actions.customFieldList(client, value)),

        salesForecastSummary: (client, value) => dispatch(actions.salesForecastSummary(client, value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesForecast);