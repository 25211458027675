import React, { Component } from "react";
import { IconButton, Grid, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar, Card } from "@material-ui/core";

import { connect } from "react-redux";
import { compose } from 'redux';
import { Link } from "react-router-dom";
class LatestActivity extends Component {
  state = {
  };


  render() {
    return (
        <Card elevation={3} className="p-16 dashboard_card mb-10">
        <Grid container justify="space-between">
        Latest Activity
            {/* <Link className="viewmore text_link" to="">View</Link> */}
        </Grid>
        
        </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LatestActivity);