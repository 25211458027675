import * as actionTypes from '../actions/actionTypes';
import { updateObject } from "../utility";
const initialState = {
  companyList: [],
  companyLogList: [],
  companyLogSidebarList: [],
  companyListOrder: [],
  companyFieldData: [],
  companyFilterListArray: [],
  company_column_order_extra: [{ statusupdatedate:1001,statusduration:1002,lastcontacted:1003,interactions:1004,inactivedays:1005}],
}

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMAPNY_FILTER_LIST:
      return updateObject(state, { companyList: action.val })
    case actionTypes.GET_COMPANY_LIST_ORDER:
      return updateObject(state, { companyListOrder: action.val })
    case actionTypes.COMPANY_FIELD_DATA:
      return updateObject(state, { companyFieldData: action.val })
    case actionTypes.GET_COMPANYFILTERS:
      return updateObject(state, { companyFilterListArray: action.val })
    case actionTypes.GET_COMPANY_LOG_LIST:
      return updateObject(state, { companyLogList: action.val })
    case actionTypes.GET_COMPANY_SIDEBAR_LOG_LIST:
      return updateObject(state, { companyLogSidebarList: action.val })
      
    default:
      return state;
  }
};
export default companyReducer;
