import Config from '../../Common/Server';

import { ApolloClient, InMemoryCache } from "apollo-boost";
import { createUploadLink } from "apollo-upload-client";

const sessionToken = localStorage.getItem('jwt_token_admin');

const link = createUploadLink({ uri: Config.GraphApi, headers: {
    authorization: `Bearer ${sessionToken}`,
    api_for:1
} });
const cache = new InMemoryCache();


var client = new ApolloClient({
    uri: Config.GraphApi,
    cache, 
    link
})

//API FOR {web ->1, crome->2, mobile->3}
if (sessionToken) {  
    client = new ApolloClient({
        uri: Config.GraphApi,
        cache, 
        link,
        request: (operation) => {
            operation.setContext({
                /* headers: {
                    authorization: `Bearer ${sessionToken}`,
                    api_for:1
                } */
            })
        }
    })
}

const initialState = {
    client: client,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}

export default reducer
