import React, { Component } from "react";
import { IconButton, Icon, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar, Card } from "@material-ui/core";

import { connect } from "react-redux";
import { compose } from 'redux';
import moment from "moment";


class DashboardSuggestion extends Component {
  state = {
  };


  render() {
    return (
      <Card elevation={3} className="p-16 h-100">
        <span className="font-weight-500">
          {moment().format("dddd, MMMM")}
        </span>
        <h6 className="font-weight-500" style={{ fontSize: "70px" }}>
          {moment().format("D")}
        </h6>
        <p className="mb-20 mt-50 text-hint text-center">No Events or Tasks Today</p>

      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(DashboardSuggestion);