import React, { Component } from "react";
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import NotificationBar from './NotificationBar';

const GET_MESSAGES = gql`
  query {
    getNotificationList{
      status
      message
      emailid
      today
      yesterday
      thisweek
      lastweek
      thismonth
      lastmonth
      earlier
    }
  }
`;
const MESSAGE_CREATED = gql`
  subscription{
    notificationSubscription{
      status
      message
      emailid
      authtoken
      notificationdata
    }
  }
`;
const NotificationAppbar = () => (
  <Query query={GET_MESSAGES}>
    {({ data, loading, subscribeToMore }) => {
      if (!data) {
        return null;
      }

      if (loading) {
        return <span>Loading ...</span>;
      }

      return (
        <NotificationData
          messages={data.getNotificationList}
          subscribeToMore={subscribeToMore}
        />
      );
    }}
  </Query>
);


class NotificationData extends Component {
  componentDidMount() {
    this.props.subscribeToMore({
      document: MESSAGE_CREATED,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        let newMessage;
        let sessionData = JSON.parse(localStorage.getItem('user_data_session_admin'));
        const sessionToken = localStorage.getItem('jwt_token_admin');
        if (subscriptionData.data.notificationSubscription.emailid === sessionData.email && subscriptionData.data.notificationSubscription.authtoken === sessionToken) {
          newMessage = subscriptionData.data.notificationSubscription.notificationdata
        }
        return Object.assign({}, prev, {
          getNotificationList: Object.assign({}, prev.getNotificationList, {
            today: [newMessage, ...prev.getNotificationList.today],
          })
        }
        );
      },
    });
  }

  render() {

    let notificationDatas = this.props;
    return (
      <React.Fragment>
        <NotificationBar data={notificationDatas} />
      </React.Fragment>
    );
  }
}

export default NotificationAppbar;