import * as actionTypes from '../actions/actionTypes';
import { updateObject } from "../utility";
const initialState = {
  contactTypeList : [],
  contactsList : [],
  contactLogList: [],
  contactLogSidebarList: [],
  contactListOrder : [],
  contactFieldData : [],
  contact_column_order_extra: [{ lastcontacted: 1003, interactions: 1004, inactivedays: 1005 }],
  contactFilterListArray : [],
}

const contactReducer = (state = initialState, action) => {
  switch (action.type) {    
    case actionTypes.GET_CONTACT_TYPE:
      return updateObject(state, { contactTypeList: action.val })
    case actionTypes.GET_CONTACT_FILTER_LIST:
      return updateObject(state, { contactsList: action.val })
    case actionTypes.GET_CONTACT_LIST_ORDER:
      return updateObject(state, { contactListOrder: action.val })
    case actionTypes.GET_CONTACT_LOG_LIST:
      return updateObject(state, { contactLogList: action.val })
    case actionTypes.GET_CONTACT_SIDEBAR_LOG_LIST:
      return updateObject(state, { contactLogSidebarList: action.val })
    case actionTypes.CONTACT_FIELD_DATA:
      return updateObject(state, { contactFieldData: action.val })
    case actionTypes.GET_CONTACTFILTER_LIST:
      return updateObject(state, { contactFilterListArray: action.val })      
    default:
      return state;
  }
};
export default contactReducer;
