import React, { Component } from "react";
import { FormHelperText, Input, FormControl, Popover, Divider, Button, IconButton, Icon, Paper, Card, DialogContent, Dialog, DialogActions, DialogTitle, Typography, MenuItem, InputLabel, Radio, FormControlLabel, RadioGroup, Badge, Drawer, Tooltip, Avatar } from "@material-ui/core";
import Grid, { default as MaterialGrid } from '@material-ui/core/Grid';
// import ReportSidebarMenu from "../Layout/ReportSidebarMenu";
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';

import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import CreateIcon from '@material-ui/icons/Create';
import moment from 'moment';//moment
import 'moment-timezone';//timezone
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import HttpsIcon from '@material-ui/icons/Https';

import { Link } from "react-router-dom";
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";

class SalesPerformanceSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateopen: false,
            dateformat: 'MM/DD/YYYY',
            selectedDate: { from: moment().startOf("month").format('YYYY-MM-DD'), to: moment().endOf('month').format('YYYY-MM-DD') },
            selectedDatelabel: "",
            selectedDateid: "4",
            definedRanges: [
                {
                    id: 3,
                    label: "This Week",
                    startDate: moment().day(1).format('YYYY-MM-DD'),
                    endDate: moment().day(7).format('YYYY-MM-DD')
                },

                {
                    id: 4,
                    label: "This Month",
                    startDate: moment().startOf("month").format('YYYY-MM-DD'),
                    endDate: moment().endOf("month").format('YYYY-MM-DD')
                },

                {
                    id: 5,
                    label: "This Quarter",
                    startDate: moment().startOf('quarter').format('YYYY-MM-DD'),
                    endDate: moment().endOf('quarter').format('YYYY-MM-DD')
                },

                {
                    id: 6,
                    label: "This Year",
                    startDate: moment().startOf('year').format('YYYY-MM-DD'),
                    endDate: moment().endOf('year').format('YYYY-MM-DD')
                },

                {
                    id: 7,
                    label: "Last 7 days",
                    startDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },

                {
                    id: 8,
                    label: "Last 30 days",
                    startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },
            ],
            fromdate: moment().startOf("month").format('YYYY-MM-DD'),
            todate: moment().endOf("month").format('YYYY-MM-DD'),
            timeno: 4,
            selectedPipline: "0",
            value_type: "1",
            datetype: 3,
            memberid: 0
        }
    }
    componentDidMount() {
        localStorage.setItem('dashboardFromDate', this.state.fromdate);
        localStorage.setItem('dashboardToDate', this.state.todate);
        localStorage.setItem('dashboardTimeNo', this.state.timeno);
    }
    componentWillReceiveProps(props) {
        if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
            let usersetting = props.settingReducer.userSettingArray.data;
            this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
        }
        if (props.reportReducer.UserDashboardId) {
            if (this.state.memberid !== props.reportReducer.UserDashboardId) {
              {
                this.setState({
                  memberid: props.reportReducer.UserDashboardId
                }, () => this.forceUpdate())
              }
            }
          }
    }

    handleDateRangeChange = (event) => {
        let daterangekey = this.findWithAttr(this.state.definedRanges, 'id', event.target.value);
        if (daterangekey != -1) {
            this.setState({
                selectedDateid: event.target.value.toString(),
                fromdate: this.state.definedRanges[daterangekey].startDate,
                todate: this.state.definedRanges[daterangekey].endDate
            })
        }
        localStorage.setItem('dashboardFromDate', this.state.definedRanges[daterangekey].startDate);
        localStorage.setItem('dashboardToDate', this.state.definedRanges[daterangekey].endDate);
        localStorage.setItem('dashboardTimeNo', parseInt(this.state.definedRanges[daterangekey].id));

        let fromDate = this.state.definedRanges[daterangekey].startDate;
        let toDate = this.state.definedRanges[daterangekey].endDate;

        let piplineparams = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: fromDate,
            todate: toDate,
            memberid: parseFloat(this.state.memberid)
        };
        this.props.piplineSummary(this.props.apolloClient.client, piplineparams);


        let forecastparams = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),

            type: parseFloat(this.state.value_type),
            fromdate: fromDate,
            todate: toDate,
            datetype: parseFloat(this.state.datetype),
            memberid: parseFloat(this.state.memberid)
        };
        this.props.salesForecastSummary(this.props.apolloClient.client, forecastparams);

        let performanceparams = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: fromDate,
            todate: toDate,
            datetype: parseFloat(this.state.datetype),
            memberid: parseFloat(this.state.memberid)
        };
        this.props.salesPerformance(this.props.apolloClient.client, performanceparams);

        let performancesummaryparams = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: fromDate,
            todate: toDate,
            datetype: parseFloat(this.state.datetype),
            timeno: parseInt(this.state.definedRanges[daterangekey].id),
            memberid: parseFloat(this.state.memberid),
        };
        this.props.salesPerformanceSummary(this.props.apolloClient.client, performancesummaryparams);

        let params = {
            filterdata: [],
            piplineid: parseFloat(this.state.selectedPipline),
            type: parseFloat(this.state.value_type),
            fromdate: fromDate,
            todate: toDate,
            memberid: parseFloat(this.state.memberid)
        };

        this.props.salesBySourceReport(this.props.apolloClient.client, params);
    }

    findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] == value) {
                return i;
            }
        }
        return -1;
    }

    setDateRange = (range) => {
        let startDate = moment(range.startDate).format('YYYY-MM-DD');
        let endDate = moment(range.endDate).format('YYYY-MM-DD');

        let activityparams = {
            fromdate: startDate,
            todate: endDate,
            memberid: parseFloat(this.state.memberid)
        };
        this.props.activityLeaderboard(this.props.apolloClient.client, activityparams);

        if (range.label) {
            this.setState({ selectedDatelabel: range.label });
        } else {
            this.setState({ selectedDate: { from: startDate, to: endDate }, selectedDatelabel: "" });
        }

        this.setState({ fromdate: startDate, todate: endDate })
    }



    render() {
        let { definedRanges, selectedDateid, dateopen, fromdate, todate } = this.state;

        return (
            <Card elevation={3} className="p-20 mb-10">
                {/* <div className="report_card"> */}
                {/* <div className="report_header"> */}
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    className="mt-40 mb-40"
                    className="crm-all-report-filters"
                >
                    <Grid item xs={11}>
                        {fromdate + " to " + todate}
                    </Grid>
                    <Grid item xs={1}>
                        <div className="report_filter" >
                            <InputLabel id="Pipeline-select-helper-label">Date range</InputLabel>
                            <Select
                                labelId="Pipeline-select-label"
                                name="selectedDateid"
                                id="Pipeline"
                                value={selectedDateid}
                                onChange={this.handleDateRangeChange}
                                style={{ width: '100px' }}
                            >
                                {definedRanges && definedRanges.map((range, i) => (
                                    <MenuItem key={i} value={range.id}>{range.label}</MenuItem>
                                ))}
                            </Select>
                        </div>
                    </Grid>
                </Grid>
                {/* </div> */}
                {/* </div> */}
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    reportReducer: state.reportReducer,
});
const mapDispatchToProps = dispatch => {
    return {

        piplineSummary: (client, value) => dispatch(actions.piplineSummary(client, value)),
        salesForecastSummary: (client, value) => dispatch(actions.salesForecastSummary(client, value)),
        salesPerformance: (client, value) => dispatch(actions.salesPerformance(client, value)),
        salesPerformanceSummary: (client, value) => dispatch(actions.salesPerformanceSummary(client, value)),
        salesBySourceReport: (client, value) => dispatch(actions.salesBySourceReport(client, value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesPerformanceSummary);