import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import "../styles/_app.scss";

import TopNavbar from "./Layout/TopNavbar";

import AdmintRoute from "./Layout/AdmintRoute";


import { connect } from "react-redux";
import * as actionCreators from '../store/actions/index'

import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import SignIn from '../components/views/sessions/SignIn';
import SignUp from '../components/views/sessions/SignUp';
import CheckEmail from '../components/views/email/CheckEmail';
import Page404 from '../components/views/page404';
import MultipleCompany from '../components/views/dashboard/MultipleCompany';
import { ApolloProvider } from '@apollo/react-hooks';
import Crome from "../crome";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      renderNow: false,
      open: false,
      message: '',
      color: '',
      bgcolor: '',
      openFixedUserDetail: true,
      isShowMultiCompany: false,
      accessPage: [0, 1, 2, 3, 4, 5],
    }
  }
  componentWillMount() {
    var userDataSession = JSON.parse(localStorage.getItem('user_data_session_admin'));
    this.chkCompanyIdUser();
    if (userDataSession !== null) {
      this.props.getLoginUserPermission(this.props.apolloClient.client);
      // this.props.setUserRole();
      this.setState({ isLogin: true });
      if (userDataSession.isMultiple == true) {
        let getMultipleCompanyId = localStorage.getItem('setMultipleCompanyId');
        if (getMultipleCompanyId !== null) {
          this.setState({ isLogin: true });
        } else {
          this.setState({ isShowMultiCompany: true })
        }
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.settingReducer.userPermissionDetails.data) {
      let mainData = []
      let udata = props.settingReducer.userPermissionDetails.data.data
      if (udata.visibilityaccess.length > 0 &&
        udata.teampermissionstatus === true &&
        this.props.authReducer.userRole.id === 3) {
        udata.visibilityaccess.map((page, i) => {
          if (page.isaccess === true) {
            mainData.push(page.sourcetype)
          }
        })
      } else {
        mainData = [1, 2, 3, 4, 5]
      }
      this.setState({ accessPage: mainData })
    }
    if (props.notification.message) {
      if (props.notification.message.error) {
        this.setState({
          message: props.notification.message.notify_msg,
          color: '#e0868e',
          bgcolor: "#f8d7da",
          renderNow: true,
          open: true
        })
      } else {
        this.setState({
          message: props.notification.message.notify_msg,
          color: '#5d7e6f',
          bgcolor: "#c8e2b2",
          renderNow: true,
          open: true
        })
      }
      let that = this;
      setTimeout(
        function () {
          that.toggleSnackbar();
        }.bind(this),
        4000
      );
    }
  }
  chkCompanyIdUser() {
    //console.log("chkCompanyIdUser==>")
  }

  toggleSnackbar = () => {
    this.props.clearNotification()
    this.setState({ open: false })
  }


  render() {
    let { accessPage } = this.state;
    var userDataSession = JSON.parse(localStorage.getItem('user_data_session_admin'));

    return (
      <>
        <Router>
          <Route path='/404' component={Page404} />
          {this.state.isShowMultiCompany ?
            <MultipleCompany data={this} />
            :
            ''
          }
          {this.state.isLogin && !this.state.isShowMultiCompany ?
            <React.Fragment>
              <TopNavbar />
              <AdmintRoute />
            </React.Fragment>
            :
            <React.Fragment>
              <Route path='/' exact component={SignIn} />
              <Route path='/signup' exact component={SignUp} />
              <Route path='/email/confirm-email' exact component={CheckEmail} />
              <Redirect path="/" />
            </React.Fragment>
          }
        </Router>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.authReducer,
  notification: state.notification,
  apolloClient: state.apolloClient,
  settingReducer: state.settingReducer,
  authReducer: state.authReducer

});
const mapDispatchToProps = dispatch => {
  return {
    getLoginUserPermission: (client) => dispatch(actionCreators.getLoginUserPermission(client)),
    setUserRole: () => dispatch(actionCreators.setUserRole()),
    clearNotification: () => dispatch(actionCreators.clearNotification()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);