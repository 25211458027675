import React, { Component } from "react";
import ReportSidebarMenu from '../Layout/AdminSidebarMenu';
import "../../styles/_settings.scss"
import { Route, withRouter } from 'react-router-dom';

import { connect } from "react-redux";
import * as actions from '../../store/actions/index';
import UnAuthorized from '../../components/views/UnAuthorized';

import Dashboard from "../Dashboard/CompanyDashboard";
import CompanyList from "../CompanyList/CompanyList";
import Users from "../UserManagement/UserManagement";

class ReportRoute extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accessPage: [1, 2, 3]
        }
    }
    getUserPermission = () => {
        if (this.props.settingReducer.userPermissionDetails.data) {
            let mainData = []
            let udata = this.props.settingReducer.userPermissionDetails.data.data
            if (udata.reportVisibilityaccess.length > 0 &&
                udata.teampermissionstatus === true &&
                this.props.authReducer.userRole.id === 3) {
                udata.reportVisibilityaccess.map((report, i) => {
                    if (report.isaccess === true && this.state.accessPage.includes(report.reporttype)) {
                        mainData.push(report.reporttype)
                    }
                })
            } else {
                mainData = [1, 2, 3];
            }
            this.setState({ accessPage: mainData })
        }
    }
    componentWillReceiveProps(props) {
        this.getUserPermission()
    }
    // this method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
    handleClick(item) {
        this.setState(prevState => (
            { [item]: !prevState[item] }
        ))
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.state.accessPage.length > 0 ?
                        <>
                            <ReportSidebarMenu />
                            <div className="lead_content right-16 settings_view crm-all-reports-page-wrapper">
                                <Route exact path="/" component={Dashboard} />
                                <Route exact path="/dashboard" component={Dashboard} />
                                <Route exact path="/companies" component={CompanyList} />
                                <Route exact path="/users" component={Users} />   
                            </div>
                        </>
                        :
                        <UnAuthorized />
                }
            </React.Fragment>
        );
    }
}



const mapStateToProps = state => ({
    notification: state.notification,
    apolloClient: state.apolloClient,
    settingReducer: state.settingReducer,
    authReducer: state.authReducer
});
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportRoute));