import * as actionTypes from '../actions/actionTypes';
import { updateObject } from "../utility";
const initialState = {
  opportunityList : [],
  opportunityLogList: [],
  opportunityLogSidebarList: [],
  opportunityListOrder : [],
  opportunityFieldData : [],
  opportunity_column_order_extra: [{ statusupdatedate: 1001, statusduration: 1002, lastcontacted: 1003, interactions: 1004, inactivedays: 1005, dayinstage:1006 }],
  opportunityFilterListArray : [],
  opportunityContactList : [],
  opportunityCompanyList : [],
}

const opportunityReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case actionTypes.GET_OPPORTUNITY_FILTER_LIST:
      return updateObject(state, { opportunityList: action.val })
    case actionTypes.GET_OPPORTUNITY_LIST_ORDER:
      return updateObject(state, { opportunityListOrder: action.val })
    case actionTypes.GET_OPPORTUNITY_LOG_LIST:
      return updateObject(state, { opportunityLogList: action.val })
    case actionTypes.GET_OPPORTUNITY_SIDEBAR_LOG_LIST:
      return updateObject(state, { opportunityLogSidebarList: action.val })
      
    case actionTypes.OPPORTUNITY_FIELD_DATA:
      return updateObject(state, { opportunityFieldData: action.val })
    case actionTypes.GET_OPPORTUNITYFILTER_LIST:
      return updateObject(state, { opportunityFilterListArray: action.val })
    case actionTypes.OPPORTUNITY_CONTACT_LIST:
      return updateObject(state, { opportunityContactList: action.val })
    case actionTypes.OPPORTUNITY_COMPANY_LIST:
      return updateObject(state, { opportunityCompanyList: action.val })
  
      
   
      
    default:
      return state;
  }
};
export default opportunityReducer;
