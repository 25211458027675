import React from "react";
import { Dialog, Button, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';

const ConfirmationDialog = (props) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={props.open}
            onClose={props.onConfirmDialogClose}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="ar-remove-dialog-title"
        >
            <DialogTitle id="simple-dialog-title">Are you sure you want to remove ? </DialogTitle>
            <DialogActions>
                <Button onClick={props.onConfirmDialogClose} color="primary">
                    No
            </Button>
                <Button type="Button" onClick={props.onYesClick} className="btn_Primary">
                    Yes
            </Button>
            </DialogActions>
        </Dialog>

    );
};
export default ConfirmationDialog;